import { useState, useRef, useEffect, useMemo } from 'react';

import * as Styled from './leaderboard.styled';

import FullscreenContainer from '../../components/fullscreen_container/fullscreen_container';
import {
  SpacerHorizontal,
  SpacerVertical,
} from '../../components/spacer/spacer';
import CustomLoader from '../../components/loader/custom_loader';

import { formatWalletAddress, priceFormat } from '../../utils/helpers';

import useLeaderboard from '../../hooks/use_leaderboard';

import SimpleBarReact from 'simplebar-react';

const TABLE_LIMIT = 10;

const LeaderboardContent = ({
  score,
  userScoreIndex,
  userScore,
  numberOfPages,
}) => {
  const [pageIndex, setPageIndex] = useState(0);

  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current?.getScrollElement()?.scrollTo(0, 0);
  }, [pageIndex]);

  const getScores = (index, s, nop) => {
    const startIndex = index * TABLE_LIMIT;

    let scores = [];

    if (index + 1 < nop) {
      scores = s?.slice(startIndex, startIndex + TABLE_LIMIT);
    } else {
      scores = s?.slice(startIndex, s?.length);
    }

    if (scores.length < 4) {
      const length = 4 - scores.length;
      for (let i = 0; i < length; i++) {
        scores.push({});
      }
    }

    return scores;
  };

  const getPagination = (index, nop) => {
    const paginationArray = Array.from({ length: nop }, (_, i) => i + 1);

    if (index === 0) {
      return paginationArray?.slice(0, 3);
    } else if (index === paginationArray?.length - 1) {
      if (index > 2) {
        return paginationArray?.slice(
          paginationArray?.length - 3,
          paginationArray?.length
        );
      }
      return paginationArray?.slice(0, paginationArray?.length);
    }

    return paginationArray?.slice(index - 1, index + 2);
  };

  const shouldShowUserAddress = (index, usi) => {
    return usi !== -1 && index + 1 !== Math.ceil((usi + 1) / TABLE_LIMIT);
  };

  const scoreSlice = useMemo(
    () => getScores(pageIndex, score, numberOfPages),
    [pageIndex, score, numberOfPages]
  );
  const pagination = useMemo(
    () => getPagination(pageIndex, numberOfPages),
    [pageIndex, numberOfPages]
  );
  const showUserAddress = useMemo(
    () => shouldShowUserAddress(pageIndex, userScoreIndex),
    [pageIndex, userScoreIndex]
  );

  return (
    <>
      <Styled.LeaderboardTitleContainer>
        <Styled.LeaderboardTitle>DEO Arena Season 7</Styled.LeaderboardTitle>
      </Styled.LeaderboardTitleContainer>
      <SpacerHorizontal size={25} />
      <SimpleBarReact
        ref={scrollRef}
        style={{ height: '372px', paddingRight: '25px' }}
        classNames={{
          scrollbar: 'simplebar-scrollbar',
        }}
      >
        {scoreSlice.map((item, index) => (
          <Styled.LeaderboardItemContainer
            key={index.toString()}
            userAddress={
              userScoreIndex !== -1 && userScore?.accountId === item.accountId
            }
          >
            <Styled.ContainerRow>
              {item.place && (
                <Styled.LeaderboardItemIndexContainer>
                  <Styled.LeaderboardItemIndexText>
                    {item.place}
                  </Styled.LeaderboardItemIndexText>
                </Styled.LeaderboardItemIndexContainer>
              )}
              {item.username ? (
                <Styled.LeaderboardItemSpanContainer>
                  {item?.accountId?.startsWith('cn') ? (
                    <Styled.LeaderboardItemLogoContainer>
                      <Styled.LeaderboardItemNetwork src={'images/sora.svg'} />
                    </Styled.LeaderboardItemLogoContainer>
                  ) : (
                    <Styled.LeaderboardItemLogosContainer>
                      <Styled.LeaderboardItemNetwork src={'images/eth.svg'} />
                      <Styled.LeaderboardItemNetworkRight
                        src={'images/astar.svg'}
                      />
                    </Styled.LeaderboardItemLogosContainer>
                  )}
                  <Styled.LeaderboardItemAddress>
                    {item.username !== item.accountId
                      ? item.username
                      : formatWalletAddress(item.username, 16)}
                  </Styled.LeaderboardItemAddress>
                </Styled.LeaderboardItemSpanContainer>
              ) : (
                <Styled.LeaderboardItemAddress>
                  No data
                </Styled.LeaderboardItemAddress>
              )}
            </Styled.ContainerRow>
            {item.points && (
              <Styled.LeaderboardItemRightContainer>
                <Styled.LeaderboardItemIndexText>
                  {priceFormat(item.points)}
                </Styled.LeaderboardItemIndexText>
              </Styled.LeaderboardItemRightContainer>
            )}
          </Styled.LeaderboardItemContainer>
        ))}
      </SimpleBarReact>
      {showUserAddress && (
        <>
          <SpacerHorizontal size={10} />
          <Styled.LeaderboardItemContainer userAddress>
            <Styled.ContainerRow>
              <Styled.LeaderboardItemIndexContainer>
                <Styled.LeaderboardItemIndexText>
                  {userScore?.place}
                </Styled.LeaderboardItemIndexText>
              </Styled.LeaderboardItemIndexContainer>
              <Styled.LeaderboardItemSpanContainer>
                {userScore?.accountId?.startsWith('cn') ? (
                  <Styled.LeaderboardItemLogoContainer>
                    <Styled.LeaderboardItemNetwork src={'images/sora.svg'} />
                  </Styled.LeaderboardItemLogoContainer>
                ) : (
                  <Styled.LeaderboardItemLogosContainer>
                    <Styled.LeaderboardItemNetwork src={'images/eth.svg'} />
                    <Styled.LeaderboardItemNetworkRight
                      src={'images/astar.svg'}
                    />
                  </Styled.LeaderboardItemLogosContainer>
                )}
                <Styled.LeaderboardItemAddress>
                  {userScore?.username !== userScore?.accountId
                    ? userScore?.username
                    : formatWalletAddress(userScore?.username, 16)}
                </Styled.LeaderboardItemAddress>
              </Styled.LeaderboardItemSpanContainer>
            </Styled.ContainerRow>
            <Styled.LeaderboardItemRightContainer>
              <Styled.LeaderboardItemIndexText>
                {priceFormat(userScore?.points)}
              </Styled.LeaderboardItemIndexText>
            </Styled.LeaderboardItemRightContainer>
          </Styled.LeaderboardItemContainer>
        </>
      )}
      <SpacerHorizontal size={25} />
      <Styled.PaginationContainer>
        <Styled.PaginationButtonContainer
          onClick={() => {
            if (pageIndex > 0) {
              setPageIndex(pageIndex - 1);
            }
          }}
        >
          <Styled.ButtonImage image={'images/pagination_left.png'} />
          <SpacerVertical size={10} />
          <Styled.PaginationButtonText active={pageIndex > 0}>
            Prev
          </Styled.PaginationButtonText>
        </Styled.PaginationButtonContainer>
        <SpacerVertical size={25} />
        {pagination.map((page) => {
          return (
            <Styled.PaginationButtonPage
              active={page === pageIndex + 1}
              key={page}
              onClick={() => {
                if (page - 1 !== pageIndex) {
                  setPageIndex(page - 1);
                }
              }}
            >
              <Styled.PaginationButtonText active>
                {page}
              </Styled.PaginationButtonText>
            </Styled.PaginationButtonPage>
          );
        })}
        <SpacerVertical size={25} />
        <Styled.PaginationButtonContainer
          onClick={() => {
            if (pageIndex < numberOfPages - 1) {
              setPageIndex(pageIndex + 1);
            }
          }}
        >
          <Styled.PaginationButtonText active={pageIndex < numberOfPages - 1}>
            Next
          </Styled.PaginationButtonText>
          <SpacerVertical size={10} />
          <Styled.ButtonImage image={'images/pagination_right.png'} />
        </Styled.PaginationButtonContainer>
      </Styled.PaginationContainer>
    </>
  );
};

const Leaderboard = () => {
  const { score, loading, userScoreIndex, userScore, numberOfPages } =
    useLeaderboard();

  return (
    <FullscreenContainer>
      <Styled.LeaderboardContent>
        {loading ? (
          <CustomLoader fullscreen={false} />
        ) : (
          <LeaderboardContent
            score={score}
            userScoreIndex={userScoreIndex}
            userScore={userScore}
            numberOfPages={numberOfPages}
          />
        )}
      </Styled.LeaderboardContent>
    </FullscreenContainer>
  );
};

export default Leaderboard;
