import { createContext, useEffect, useCallback, useRef, useState } from 'react';

import { WsProvider } from '@polkadot/rpc-provider/ws';
import { ApiPromise, Keyring } from '@polkadot/api';
import { options } from '@sora-substrate/api';
import { web3Accounts, web3Enable } from '@polkadot/extension-dapp';
import { ethers } from 'ethers';

import {
  POLKADOT_ACCOUNT,
  SORA_API,
  APP_NAME,
  DEMETER_ADDRESS,
  DEMETER_ADDRESS_ASTAR,
  DEMETER_ADDRESS_ETHEREUM,
  SORA,
  ASTAR,
  ASTAR_CHAIN_ID,
  METAMASK_EXTENSION,
  ETHEREUM,
  ETHEREUM_CHAIN_ID,
} from '../constants';
import { generateErrorMessage, parse } from '../utils/helpers';

import usePersistState from '../hooks/use_persist_state';

import { tokenABI } from '../contracts/token_abi';

const astarNetworkParams = [
  {
    chainId: ASTAR_CHAIN_ID,
    chainName: 'Astar Network',
    nativeCurrency: {
      name: 'Astar',
      symbol: 'ASTR',
      decimals: 18,
    },
    rpcUrls: ['https://astar.api.onfinality.io/public'],
    blockExplorerUrls: ['https://astar.subscan.io/block'],
    iconUrls: [''],
  },
];

const networkOptions = [
  { id: SORA, name: 'SORA', logo: 'images/sora.svg' },
  { id: ASTAR, name: 'Astar', logo: 'images/astar.svg' },
  { id: ETHEREUM, name: 'Ethereum', logo: 'images/eth.svg' },
];

export const PolkadotContext = createContext();

export const PolkadotProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [deoBalance, setDeoBalance] = useState(0);
  const [network, setNetwork] = usePersistState(
    'WALLET NOT CONNECTED',
    'NETWORK'
  );

  const api = useRef(null);
  const keyring = useRef(null);

  const networkProvider = useRef(null);
  const tokenContract = useRef(null);

  const setTokenContract = useCallback((signer, n) => {
    const demeterAddress =
      n === ASTAR ? DEMETER_ADDRESS_ASTAR : DEMETER_ADDRESS_ETHEREUM;

    try {
      tokenContract.current = new ethers.Contract(
        demeterAddress,
        tokenABI,
        signer
      );
    } catch {}
  }, []);

  const saveSelectedAccount = useCallback(
    async (account, networkName) => {
      if (account !== selectedAccount) {
        if (networkName === SORA) {
          localStorage.setItem(POLKADOT_ACCOUNT, JSON.stringify(account));
          setSelectedAccount(account);
        }

        if (networkName === ASTAR || networkName === ETHEREUM) {
          networkProvider.current = account;
          const signer = account?.getSigner();
          const address = await signer?.getAddress();

          setTokenContract(signer, networkName);

          localStorage.setItem(POLKADOT_ACCOUNT, JSON.stringify(address));
          setSelectedAccount({ address });
          setLoading(false);
        }
      }
    },
    [selectedAccount, setLoading, setTokenContract]
  );

  const setApi = useCallback(async () => {
    /** Connect to Sora network **/
    const provider = new WsProvider(SORA_API);

    const soraAPI = new ApiPromise(options({ provider }));

    await soraAPI.isReady;
    api.current = soraAPI;
  }, []);

  const connectToPolkadotExtension = useCallback(async () => {
    const accountJSON = localStorage.getItem(POLKADOT_ACCOUNT);
    const account = JSON.parse(accountJSON) || null;

    // this call fires up the authorization popup
    const extensions = await web3Enable(APP_NAME);

    if (extensions.length !== 0) {
      // we are now informed that the user has at least one extension and that we
      // will be able to show and use accounts
      const allAccounts = await web3Accounts();

      if (allAccounts !== null && allAccounts.length > 0) {
        setAccounts(allAccounts);

        if (account !== null) {
          const accountsFiltered = allAccounts.filter(
            (acc) => acc?.meta?.name === account?.meta?.name
          );
          if (accountsFiltered.length > 0) {
            setSelectedAccount(account);
          }
        }
      }
    }
  }, []);

  const setKeyring = useCallback(async () => {
    keyring.current = new Keyring();
  }, []);

  const getDeoBalance = useCallback(async () => {
    if (network?.id === SORA) {
      const deoBalance = await api.current?.rpc?.assets?.freeBalance(
        selectedAccount?.address,
        DEMETER_ADDRESS
      );

      setDeoBalance(parse(deoBalance?.toHuman()?.balance, false));
    }

    if (network?.id === ASTAR || network?.id === ETHEREUM) {
      const tokenBalance = await tokenContract.current?.balanceOf(
        selectedAccount?.address
      );

      try {
        setDeoBalance(ethers.utils.formatUnits(tokenBalance));
      } catch {
        setDeoBalance('0.0');
      }
    }
  }, [api, selectedAccount, network]);

  const connectToMetamaskAccount = useCallback(
    async (n) => {
      try {
        const provider = new ethers.providers.Web3Provider(
          window.ethereum,
          'any'
        );
        await provider.send('eth_requestAccounts', []);

        // The MetaMask plugin also allows signing transactions to
        // send ether and pay to change state within the blockchain.
        // For this, you need the account signer...
        saveSelectedAccount(provider, n.id);
      } catch (error) {
        setLoading(false);
        generateErrorMessage(error);
      }
    },
    [saveSelectedAccount, setLoading]
  );

  const connectToMetamask = useCallback(
    async (n) => {
      if (!window.ethereum) {
        setLoading(false);
        window.open(METAMASK_EXTENSION, '_blank');
      } else {
        if (n.id === ASTAR) {
          try {
            await window.ethereum?.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: ASTAR_CHAIN_ID }],
            });
            connectToMetamaskAccount(n);
          } catch (error) {
            if (error.code === 4902) {
              try {
                await window.ethereum?.request({
                  method: 'wallet_addEthereumChain',
                  params: astarNetworkParams,
                });
                connectToMetamaskAccount(n);
              } catch (addError) {
                setLoading(false);
                generateErrorMessage(addError);
              }
            } else {
              setLoading(false);
            }
          }
        }

        if (n.id === ETHEREUM) {
          try {
            await window.ethereum?.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: ETHEREUM_CHAIN_ID }],
            });
            connectToMetamaskAccount(n);
          } catch (error) {
            setLoading(false);
            generateErrorMessage(error);
          }
        }
      }
    },
    [connectToMetamaskAccount, setLoading]
  );

  const logout = useCallback(() => {
    localStorage.removeItem(POLKADOT_ACCOUNT);
    setSelectedAccount(null);
    setAccounts(null);
    if (networkProvider.current) networkProvider.current = null;
    if (tokenContract.current) tokenContract.current = null;
  }, []);

  const disconnect = useCallback(() => {
    api.current?.disconnect();
    api.current = null;
    window.ethereum?.removeAllListeners();
  }, []);

  const handleChainChanged = useCallback(
    async (chainId) => {
      if (
        chainId?.toString() === ASTAR_CHAIN_ID ||
        chainId?.toString() === ETHEREUM_CHAIN_ID
      ) {
        const netw =
          chainId?.toString() === ETHEREUM_CHAIN_ID
            ? networkOptions[2]
            : networkOptions[1];
        await connectToMetamaskAccount(netw);
        setNetwork(netw);
      } else {
        setNetwork('WALLET NOT CONNECTED');
      }
    },
    [connectToMetamaskAccount, setNetwork]
  );

  const handleAccountChanged = useCallback(
    (accounts) => {
      if (accounts.length > 0) {
        const address = ethers.utils.getAddress(accounts[0]);
        localStorage.setItem(POLKADOT_ACCOUNT, JSON.stringify(address));
        setSelectedAccount({ address });
      } else {
        logout();
        disconnect();
        setNetwork('WALLET NOT CONNECTED');
      }
    },
    [logout, disconnect, setNetwork]
  );

  const setMetamaskListener = useCallback(async () => {
    try {
      window.ethereum.on('chainChanged', handleChainChanged);
      window.ethereum.on('accountsChanged', handleAccountChanged);
    } catch {}
  }, [handleChainChanged, handleAccountChanged]);

  const connectToChain = useCallback(
    async (network) => {
      await connectToMetamask(network);
      setMetamaskListener();
    },
    [connectToMetamask, setMetamaskListener]
  );

  const init = useCallback(
    async (n) => {
      if (n.id === SORA) {
        await setApi();
        await connectToPolkadotExtension();
        setKeyring();
        setLoading(false);
      }

      if (n.id === ASTAR || n.id === ETHEREUM) {
        await connectToChain(n);
      }
    },
    [setApi, connectToPolkadotExtension, setKeyring, connectToChain]
  );

  const switchNetwork = useCallback(
    async (n) => {
      disconnect();
      logout();

      if (n?.id) {
        setLoading(true);
        await init(n);
        setNetwork(n);
      } else {
        setNetwork('WALLET NOT CONNECTED');
      }
    },
    [init, setNetwork, logout, disconnect]
  );

  useEffect(() => {
    if (network?.id) {
      init(network);
    } else {
      localStorage.removeItem(POLKADOT_ACCOUNT);
      setLoading(false);
    }

    return () => {
      disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedAccount) {
      getDeoBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount]);

  return (
    <PolkadotContext.Provider
      value={{
        api: api.current,
        keyring: keyring.current,
        network,
        networkOptions,
        switchNetwork,
        accounts,
        selectedAccount,
        saveSelectedAccount,
        loading,
        deoBalance,
        getDeoBalance,
        connectToChain,
        networkProvider: networkProvider.current,
        tokenContract: tokenContract.current,
      }}
    >
      {children}
    </PolkadotContext.Provider>
  );
};
