import BigNumber from 'bignumber.js';
import { Codec } from '@polkadot/types/types';
import isNil from 'lodash/fp/isNil';

type NumberType = Codec | string | number | BigNumber | FPNumber;

BigNumber.config({
  FORMAT: {
    decimalSeparator: '.',
    groupSeparator: '',
    fractionGroupSeparator: '',
  },
});

const checkFinityString = (str: string) =>
  !['-Infinity', 'Infinity', 'NaN'].includes(str);

export class FPNumber {
  public static DEFAULT_PRECISION = 18;
  public static DEFAULT_ROUND_MODE: BigNumber.RoundingMode = 3;

  public static fromNatural(
    value: number | string,
    precision: number = FPNumber.DEFAULT_PRECISION
  ): FPNumber {
    return new FPNumber(value, precision);
  }

  public value: BigNumber;

  constructor(data: NumberType, public precision = FPNumber.DEFAULT_PRECISION) {
    if (data instanceof BigNumber) {
      this.value = data;
    } else if (data instanceof FPNumber) {
      this.value = data.value;
      this.precision = data.precision;
    } else {
      const formatted = () => {
        if (typeof data === 'number') {
          return (data * 10 ** precision).toFixed();
        }
        if (typeof data === 'string') {
          if (!checkFinityString(data)) {
            return data;
          }
          const withoutFormatting = data.replace(/[, ]/g, '');
          const [integer, fractional] = withoutFormatting.split('.');
          let fractionalPart = '';
          if (fractional) {
            fractionalPart =
              fractional.length > precision
                ? fractional.substring(0, precision)
                : `${fractional}${Array(precision - fractional.length)
                    .fill(0)
                    .join('')}`;
          } else {
            fractionalPart = `${Array(precision).fill(0).join('')}`;
          }
          return `${integer}${fractionalPart}`;
        }
        if ('toString' in (data as any)) {
          const json = data.toJSON() as any;
          // `BalanceInfo` or `Balance` check
          return json && !isNil(json.balance)
            ? `${json.balance}`.replace(/[, ]/g, '')
            : data.toString();
        }
        return 0;
      };
      this.value = new BigNumber(formatted()).dp(
        0,
        FPNumber.DEFAULT_ROUND_MODE
      );
    }
  }

  public isFinity(): boolean {
    return this.value.isFinite();
  }

  public bnToString(dp: number = 0): string {
    // Return 0 if the value is Infinity, -Infinity and NaN
    if (!this.isFinity()) {
      return '0';
    }
    return this.value.dp(dp, FPNumber.DEFAULT_ROUND_MODE).toFixed();
  }
}
