import React from 'react';

import * as Styled from './input.styled';

const Input = ({
  name,
  value,
  type = 'number',
  isValid = true,
  errorMessage,
  handleChange,
  disabled,
  placeholder = 'Enter value',
  trailing,
  tooltip,
  fullWidth = false,
}) => {
  return (
    <>
      <Styled.InputContainer isValid={isValid} fullWidth={fullWidth}>
        <Styled.InputField
          id={name}
          name={name}
          value={value}
          title={tooltip}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          onChange={handleChange}
          autoComplete={'off'}
          fullWidth={fullWidth}
        />
        {trailing}
      </Styled.InputContainer>
      {!isValid ? (
        <Styled.InputErrorText>{errorMessage}</Styled.InputErrorText>
      ) : null}
    </>
  );
};

export default Input;
