import styled from 'styled-components/macro';

import { colors } from '../../styles/colors';
import { device } from '../../styles/device';
import { fontSizes, fontStyles } from '../../styles/fonts';

export const Container = styled.div`
  background-image: url(images/footer_bg.jpg);
  height: calc(100vw / 3.89);
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px;
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    height: 263px;
  }
`;

export const TopSection = styled.div`
  flex: 2;
  display: inherit;
  align-items: center;
  justify-content: space-between;
  padding: 20px 100px;

  @media ${device.laptopL} {
    padding: 20px 25px;
  }

  @media ${device.laptop} {
    padding: 10px 25px;
  }
`;

export const BottomSection = styled.div`
  flex: 1;
  display: inherit;
  align-items: center;
`;

export const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 100px;

  @media ${device.laptopL} {
    padding: 20px 25px;
  }

  @media ${device.laptop} {
    padding: 10px 25px;
  }
`;

export const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  display: inherit;
  height: 100%;
  align-items: center;
  min-width: 350px;
`;

export const WrapperColumn = styled.div`
  display: inherit;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  min-width: 350px;
`;

export const WrapperLogos = styled.div`
  display: inherit;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  min-width: 350px;

  // @media ${device.laptop} {
  //   min-width: 0;
  // }
`;

export const LogoLink = styled.a.attrs({
  target: 'blank',
})`
  cursor: pointer;
  text-decoration: none;
`;

export const LogoSora = styled.img`
  height: 45px;
  width: auto;

  @media ${device.laptop} {
    height: 40px;
  }
`;

export const Logo = styled.img`
  height: 55px;
  width: auto;

  @media ${device.laptop} {
    height: 45px;
  }
`;

export const Copyright = styled.span`
  ${fontStyles[fontSizes.fourteen]}
  width: 100%;
  text-align: center;
  font-weight: 500;
  color: ${colors.white};

  @media ${device.laptop} {
    ${fontStyles[fontSizes.twelve]}
  }
`;

export const Label = styled.span`
  ${fontStyles[fontSizes.fourteen]}
  font-weight: 500;
  color: ${colors.white};

  @media ${device.laptop} {
    ${fontStyles[fontSizes.twelve]}
  }
`;

export const LogoArena = styled.img`
  height: 150px;
  width: auto;

  @media ${device.laptop} {
    height: 120px;
  }
`;

export const LinkWrapper = styled.div`
  display: inherit;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const LinkButton = styled.div`
  ${fontStyles[fontSizes.eighteen]}
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  color: ${colors.grey};

  &:hover {
    color: ${colors.green};
    text-shadow: -1px 1px 1px rgba(57, 13, 13, 0.67);
  }

  @media ${device.laptop} {
    ${fontStyles[fontSizes.fourteen]}
  }
`;

export const LinkA = styled.a.attrs({
  target: 'blank',
})`
  ${fontStyles[fontSizes.eighteen]}
  cursor: pointer;
  text-decoration: none;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  color: ${colors.grey};

  &:hover {
    color: ${colors.green};
    text-shadow: -1px 1px 1px rgba(57, 13, 13, 0.67);
  }

  @media ${device.laptop} {
    ${fontStyles[fontSizes.fourteen]}
  }
`;

export const IconsWrapper = styled.div`
  display: inherit;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  min-width: 350px;
`;
