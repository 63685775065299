import { toast, Slide } from 'react-toastify';

export const showSuccessNotify = (msg, toastId = null) =>
  toast.success(msg, {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: true,
    toastId,
  });

export const showErrorNotify = (msg, autoClose = false, toastId = null) =>
  toast.error(msg, {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: autoClose,
    toastId,
  });

export const showLoadingNotify = () =>
  toast.info('Transaction in progress...', {
    position: toast.POSITION.BOTTOM_RIGHT,
    closeOnClick: false,
    closeButton: false,
    autoClose: false,
  });

export const updateNotify = (toastId, msg, type) =>
  toast.update(toastId, {
    render: msg,
    type: type,
    position: toast.POSITION.BOTTOM_RIGHT,
    closeOnClick: true,
    closeButton: true,
    autoClose: true,
    transition: Slide,
  });
