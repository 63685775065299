import { useState, useCallback, useEffect, useRef } from 'react';

import { ASTAR, ETHEREUM, RANKING_API, SORA } from '../constants';

import usePolkadot from './use_polkadot';

import { getEncodedAddress } from '../utils/helpers';

import axios from 'axios';

const TABLE_LIMIT = 10;

const useLeaderboard = () => {
  const { selectedAccount, keyring, network } = usePolkadot();

  const [loading, setLoading] = useState(true);
  const [score, setScore] = useState([]);
  const [userScore, setUserScore] = useState(null);

  const userScoreIndex = useRef(-1);
  const numberOfPages = useRef(1);

  const getCurrentUserScore = useCallback(() => {
    if (network?.id === SORA) {
      userScoreIndex.current = score?.findIndex(
        (item) =>
          item.accountId ===
          getEncodedAddress(selectedAccount?.address, keyring)
      );
    }

    if (network?.id === ASTAR || network?.id === ETHEREUM) {
      userScoreIndex.current = score?.findIndex(
        (item) => item.accountId === selectedAccount?.address
      );
    }

    if (userScoreIndex.current !== -1) {
      setUserScore(score[userScoreIndex.current]);
    }
  }, [score, keyring, selectedAccount, network]);

  const getRanking = useCallback(async () => {
    await axios
      .get(RANKING_API)
      .then((response) => {
        if (response.data && response.data?.length > 0) {
          numberOfPages.current = Math.ceil(
            response.data?.length / TABLE_LIMIT
          );

          const scores = response.data?.sort(
            (score1, score2) => score1?.place - score2?.place
          );

          setScore(scores);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const init = useCallback(async () => {
    await getRanking();
  }, [getRanking]);

  useEffect(() => {
    init();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (score.length > 0 && selectedAccount) {
      getCurrentUserScore();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [score, selectedAccount]);

  return {
    score,
    loading,
    userScoreIndex: userScoreIndex.current,
    userScore,
    numberOfPages: numberOfPages.current,
  };
};

export default useLeaderboard;
