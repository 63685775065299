import * as Styled from './home.styled';

import SimpleBarReact from 'simplebar-react';

import {
  ZENLINK,
  CHIPS_PERMALINK,
  HOME_PERMALINK,
  LEADERBOARD_PERMALINK,
  POLKASWAP_SWAP,
  REFERRAL_PERMALINK,
  SORA,
} from '../../constants';

import usePolkadot from '../../hooks/use_polkadot';
import useGame from '../../hooks/use_game';

import Header from '../../components/header/header';
import { SpacerHorizontal } from '../../components/spacer/spacer';
import Footer from '../../components/footer/footer';
import Loader from '../../components/loader/loader';
import Button from '../../components/button/button';
import FloatingButton from '../../components/button/floating_button';

import { Outlet, useNavigate, Navigate, useLocation } from 'react-router-dom';

const OutletHandler = () => {
  const { loading, selectedAccount } = usePolkadot();

  const { pathname } = useLocation();

  if (!loading) {
    if (
      pathname !== HOME_PERMALINK &&
      !pathname?.includes(LEADERBOARD_PERMALINK) &&
      !selectedAccount
    ) {
      return <Navigate to={HOME_PERMALINK} replace />;
    }

    return <Outlet />;
  }

  return null;
};

const Home = () => {
  const navigate = useNavigate();

  const { network } = usePolkadot();

  const { checkIfEnoughChips } = useGame();

  const buyDEOToken = () => {
    if (network?.id === SORA) {
      window.open(POLKASWAP_SWAP);
    } else {
      window.open(ZENLINK);
    }
  };

  const openChipsModal = () => {
    navigate(CHIPS_PERMALINK, { state: HOME_PERMALINK });
  };

  const openReferralModal = () => {
    navigate(REFERRAL_PERMALINK, { state: HOME_PERMALINK });
  };

  const openLeaderboardModal = () => {
    navigate(LEADERBOARD_PERMALINK, { state: HOME_PERMALINK });
  };

  return (
    <>
      <SimpleBarReact
        style={{ height: '100vh' }}
        classNames={{
          scrollbar: 'simplebar-scrollbar',
          track: 'simplebar-track',
        }}
      >
        <Styled.Container>
          <Header
            buyDEOToken={buyDEOToken}
            openChipsModal={openChipsModal}
            openReferralModal={openReferralModal}
            openLeaderboardModal={openLeaderboardModal}
          />
          <Styled.MainSection>
            <Styled.Logo src={'images/logo_lg.png'} />
            <SpacerHorizontal size={50} />
            <Button callback={() => checkIfEnoughChips()}>
              <Styled.PlayButton>Play</Styled.PlayButton>
            </Button>
          </Styled.MainSection>
          <Styled.Section>
            <Styled.Background2 />
            <Styled.Panel image={'images/panel1.png'} aspect={1.36}>
              <Button callback={buyDEOToken}>
                <Styled.BuyButton image={'images/buy_button.svg'} bottom={75} />
              </Button>
            </Styled.Panel>
            <Styled.Panel image={'images/panel2.png'} aspect={1.15}>
              <Button callback={openChipsModal}>
                <Styled.BuyButton
                  image={'images/buy_button.svg'}
                  bottom={110}
                  left={25}
                />
              </Button>
            </Styled.Panel>
            <Styled.Panel image={'images/panel3.png'} aspect={1.29}>
              <Button walletRequired={false} callback={openLeaderboardModal}>
                <Styled.BuyButton
                  image={'images/see_button.svg'}
                  bottom={140}
                  left={50}
                />
              </Button>
            </Styled.Panel>
          </Styled.Section>
          <Footer
            buyDEOToken={buyDEOToken}
            openChipsModal={openChipsModal}
            openReferralModal={openReferralModal}
            openLeaderboardModal={openLeaderboardModal}
          />
        </Styled.Container>
        <FloatingButton />
        <OutletHandler />
      </SimpleBarReact>
      <Loader />
    </>
  );
};

export default Home;
