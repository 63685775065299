import { createGlobalStyle } from 'styled-components';
import { colors } from './colors';

const GlobalStyle = createGlobalStyle`
    *,
    *:before,
    *:after {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        -webkit-tap-highlight-color: transparent;
    }

    body {
        font-family: 'Sora', sans-serif;
    }

    :root { 
        --toastify-color-success: ${colors.green};
        --toastify-icon-color-success: var(--toastify-color-success);
        --toastify-color-error: ${colors.pink};
        --toastify-icon-color-error: var(--toastify-color-error);
        --toastify-color-info: ${colors.yellow};
        --toastify-icon-color-info: var(--toastify-color-info);
    }

    button, input, select, textarea {
        font-family: inherit !important;
        box-shadow: none !important;
        border: none !important
    }

    input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    .simplebar-track.simplebar-vertical {
        top: 0;
        width: 18px;
    }

    .simplebar-track.simplebar-horizontal {
        left: 0;
        height: 18px;
    }

    .simplebar-scrollbar {
        background: ${colors.scrollbarGradient};
        border-radius: 6px;
        width: 11px;

        &:before {
            background: none;
        }
    }

    .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
        height: 11px;
        top: 0;
    }

    .simplebar-track {
        background-color: ${colors.purple};
        border-radius: 6px;
        border: 5px solid ${colors.brown};
    }

    .fa_icons {
        color: ${colors.white};

        &:hover {
            color: ${colors.green};
        }
    }

    /*
	*
	* react-toastify styles
	*
	*/
    .Toastify__toast-container {
        max-width: 1280px;
        width: auto;
    }
    .Toastify__toast-body {
        padding: 15px;
    }
    .Toastify__toast--success {
        font-family: Sora, sans-serif;
        font-weight: 600;
        font-size: 16px;
        background: url(images/header_bg.png);
        background-repeat: no-repeat;
        color: ${colors.white};
    }
    .Toastify__toast--error {
        font-family: Sora, sans-serif;
        font-weight: 600;
        font-size: 16px;
        background: url(images/header_bg.png);
        background-repeat: no-repeat;
        color: ${colors.white};
    }
    .Toastify__toast--info {
        font-family: Sora, sans-serif;
        font-weight: 600;
        font-size: 16px;
        background: url(images/header_bg.png);
        background-repeat: no-repeat;
        color: ${colors.white};
    }
    .Toastify__close-button {
        color: ${colors.white} !important;
    }

    /* Floating button */
    @-webkit-keyframes come-in {
        0% {
            -webkit-transform: translatey(100px);
            transform: translatey(100px);
            opacity: 0;
        }
        30% {
            -webkit-transform: translateX(-50px) scale(0.4);
            transform: translateX(-50px) scale(0.4);
        }
        70% {
            -webkit-transform: translateX(0px) scale(1.2);
            transform: translateX(0px) scale(1.2);
        }
        100% {
            -webkit-transform: translatey(0px) scale(1);
            transform: translatey(0px) scale(1);
            opacity: 1;
        }
    }
    @keyframes come-in {
        0% {
            -webkit-transform: translatey(100px);
            transform: translatey(100px);
            opacity: 0;
        }
        30% {
            -webkit-transform: translateX(-50px) scale(0.4);
            transform: translateX(-50px) scale(0.4);
        }
        70% {
            -webkit-transform: translateX(0px) scale(1.2);
            transform: translateX(0px) scale(1.2);
        }
        100% {
            -webkit-transform: translatey(0px) scale(1);
            transform: translatey(0px) scale(1);
            opacity: 1;
        }
    }
    
    .floating-container {
        position: fixed;
        height: 0;
        bottom: 0;
        right: 20px;
        width: 0;
        margin: 25px 25px 50px 25px;
        z-index: 1;
    }
    .floating-container-open {
        height: 200px;
        width: auto;
    }
    .floating-button-open {
        -webkit-transform: translatey(5px);
        transform: translatey(5px);
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }
    .float-element-open {
        -webkit-animation: come-in 0.4s forwards 0.2s;
        animation: come-in 0.4s forwards 0.2s;
    }
    .floating-container .floating-button {
        position: absolute;
        width: 65px;
        height: 65px;
        background: ${colors.scrollbarGradient};
        bottom: 0;
        border-radius: 50%;
        right: 0;
        margin: auto;
        color: white;
        line-height: 65px;
        text-align: center;
        font-size: 23px;
        z-index: 100;
        cursor: pointer;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .floating-container .float-element {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: flex-end;
        padding: 5px 10px;
        border-radius: 20px;
        height: 50px;
        margin: 10px 0;
        font-size: 14px;
        color: #141036;
        font-weight: 600;
        text-align: center;
        z-index: 0;
        opacity: 0;
        -webkit-transform: translateY(100px);
                transform: translateY(100px);
    }
    .element-container a {
        text-decoration: none;
    }
    .float-element-label{
        min-width: 150px;
        color: ${colors.white};
    }
    .floating-container .float-element .material-icons {
        vertical-align: middle;
        font-size: 16px;
    }
    .floating-container .float-element:nth-child(1) {
        background: ${colors.purple};
        border: 4px solid ${colors.brown};
        box-shadow: 0 20px 20px -20px rgba(255, 255, 255, 0.2);
    }
    .floating-container .float-element:nth-child(2) {
        background: ${colors.purple};
        border: 4px solid ${colors.brown};
        box-shadow: 0 20px 20px -20px rgba(255, 255, 255, 0.2);
    }
    .floating-container .float-element:nth-child(3) {
        background: ${colors.purple};
        border: 4px solid ${colors.brown};
        box-shadow: 0 20px 20px -20px rgba(255, 255, 255, 0.2);
    }
    
    @-moz-keyframes blue {
        0%, 100% {
          -moz-box-shadow: 1px 0px 50px 10px rgba(204, 105, 35, 0.9), inset 0px 0px 10px rgba(255, 255, 255, 0.5);
          box-shadow: 1px 0px 50px 10px rgba(204, 105, 35, 0.9), inset 0px 0px 10px rgba(255, 255, 255, 0.5); 
        }
      
        50% {
          -moz-box-shadow: 0px 0px 0px 0px rgba(204, 105, 35, 0), inset 0px 0px 0px rgba(255, 255, 255, 0);
          box-shadow: 0px 0px 0px 0px rgba(204, 105, 35, 0), inset 0px 0px 0px rgba(255, 255, 255, 0); 
        } 
    }
      
    @-webkit-keyframes blue {
        0%, 100% {
          -webkit-box-shadow: 1px 0px 50px 10px rgba(204, 105, 35, 0.9), inset 0px 0px 10px rgba(255, 255, 255, 0.5);
          box-shadow: 1px 0px 50px 10px rgba(204, 105, 35, 0.9), inset 0px 0px 10px rgba(255, 255, 255, 0.5); 
        }
      
        50% {
          -webkit-box-shadow: 0px 0px 0px 0px rgba(204, 105, 35, 0), inset 0px 0px 0px rgba(255, 255, 255, 0);
          box-shadow: 0px 0px 0px 0px rgba(204, 105, 35, 0), inset 0px 0px 0px rgba(255, 255, 255, 0); 
        } 
    }
      
    @-o-keyframes blue {
        0%, 100% {
          box-shadow: 1px 0px 50px 10px rgba(204, 105, 35, 0.9), inset 0px 0px 10px rgba(255, 255, 255, 0.5); 
        }
      
        50% {
          box-shadow: 0px 0px 0px 0px rgba(204, 105, 35, 0), inset 0px 0px 0px rgba(255, 255, 255, 0); 
        } 
    }
      
    @keyframes blue {
        0%, 100% {
          box-shadow: 1px 0px 50px 10px rgba(204, 105, 35, 0.9), inset 0px 0px 10px rgba(255, 255, 255, 0.5); 
        }
      
        50% {
          box-shadow: 0px 0px 0px 0px rgba(204, 105, 35, 0), inset 0px 0px 0px rgba(255, 255, 255, 0); 
        } 
    }
    .blue {
        background-image: -webkit-linear-gradient(top, #FFE13B, #CC6923);
        background-image: -moz-linear-gradient(top, #FFE13B, #CC6923);
        background-image: -o-linear-gradient(top, #FFE13B, #CC6923);
        background-image: linear-gradient(to bottom, #FFE13B, #CC6923);
        -webkit-animation: blue 2s infinite;
        -moz-animation: blue 2s infinite;
        -o-animation: blue 2s infinite;
        animation: blue 2s infinite; 
    }
`;

export default GlobalStyle;
