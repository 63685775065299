import * as Styled from './loader.styled';

import Spinner from '../spinner/spinner';

const CustomLoader = ({ fullscreen = true }) => {
  if (fullscreen) {
    return (
      <Styled.Container>
        <Styled.Loader>
          <Spinner />
        </Styled.Loader>
      </Styled.Container>
    );
  }

  return (
    <Styled.Loader>
      <Spinner />
    </Styled.Loader>
  );
};

export default CustomLoader;
