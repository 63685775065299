import { useState } from 'react';

import * as Styled from './button.styled';

const ButtonAsync = ({ title, onClick, image, small = false }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);

    await onClick();

    setLoading(false);
  };

  return (
    <Styled.ButtonAsync disabled={loading} onClick={handleClick} image={image}>
      <Styled.ButtonText small={small}>{title}</Styled.ButtonText>
    </Styled.ButtonAsync>
  );
};

export default ButtonAsync;
