import { useEffect, useContext, useCallback } from 'react';

import * as Styled from './game.styled';

import usePolkadot from '../../hooks/use_polkadot';

import { HOME_PERMALINK, SORA } from '../../constants';

import { Unity, useUnityContext } from 'react-unity-webgl';
import {
  Navigate,
  useNavigate,
  UNSAFE_NavigationContext as NavigationContext,
} from 'react-router-dom';
import { getEncodedAddress } from 'utils/helpers';

const Game = () => {
  const { selectedAccount, keyring, network } = usePolkadot();

  if (selectedAccount) {
    return (
      <GameContent
        selectedAccount={selectedAccount}
        network={network}
        keyring={keyring}
      />
    );
  }

  return <Navigate to={HOME_PERMALINK} replace />;
};

const GameContent = ({ selectedAccount, network, keyring }) => {
  const { navigator } = useContext(NavigationContext);

  const navigate = useNavigate();

  const {
    unityProvider,
    isLoaded,
    loadingProgression,
    unload,
    addEventListener,
    removeEventListener,
    sendMessage,
  } = useUnityContext({
    loaderUrl: 'unitybuild/season8.loader.js',
    dataUrl: 'unitybuild/season8.data',
    frameworkUrl: 'unitybuild/season8.framework.js',
    codeUrl: 'unitybuild/season8.wasm',
  });

  const setAddress = useCallback(() => {
    const address =
      network?.id === SORA
        ? getEncodedAddress(selectedAccount?.address, keyring)
        : selectedAccount?.address;

    sendMessage('GameWebsiteCommunication', 'SetAdress', address);
    sendMessage('GameWebsiteCommunication', 'SetNetwork', network?.id);
    sendMessage('GameWebsiteCommunication', 'SetQueueName', '1v1-EU');
    sendMessage(
      'GameWebsiteCommunication',
      'SetBuildId',
      '7f329128-0ff9-4b9c-a51e-e6fc52150cd4'
    );
  }, [selectedAccount, sendMessage, network, keyring]);

  useEffect(() => {
    addEventListener('GetAdress', setAddress);
    return () => {
      removeEventListener('GetAdress', setAddress);
    };
  }, [addEventListener, removeEventListener, setAddress]);

  useEffect(() => {
    const unblock = navigator.block(async (tx) => {
      if (isLoaded) {
        await unload();
        unblock();
        navigate(-1);
      }
    });
    return unblock;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigator, isLoaded]);

  const loadingPercentage = Math.round(loadingProgression * 100);

  return (
    <Styled.Container>
      {!isLoaded && (
        <Styled.LoadingContainer>
          <Styled.Loading>Loading... ({loadingPercentage}%)</Styled.Loading>
        </Styled.LoadingContainer>
      )}
      <Unity
        unityProvider={unityProvider}
        style={{
          width: '100%',
          height: '100%',
          minWidth: '1024px',
          visibility: isLoaded ? 'visible' : 'hidden',
        }}
      />
    </Styled.Container>
  );
};

export default Game;
