import multipliers from './multipliers';
import { showErrorNotify } from './toast';

import { createIntl, createIntlCache } from 'react-intl';

import { ASTAR, ETHEREUM, SORA, TOAST_ID } from '../constants';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: 'en-US',
  },
  cache
);

export const getAvatarTitle = (accountName) => {
  if (accountName !== null) {
    const nameSplit = accountName.split(' ');

    if (nameSplit.length > 1) {
      return (
        nameSplit[0].charAt(0).toUpperCase() +
        nameSplit[1].charAt(0).toUpperCase()
      );
    }

    return accountName.charAt(0);
  }

  return '';
};

export const firstName = (accountName) => {
  if (accountName !== null) {
    const nameSplit = accountName.split(' ');

    if (nameSplit.length > 1) {
      return nameSplit[0];
    }

    return accountName;
  }

  return '';
};

export const formatWalletAddress = (address, formatDelimiter = 10) => {
  if (address && address.length > 20) {
    return `${address.substring(0, formatDelimiter)}-${address.substring(
      address.length - formatDelimiter,
      address.length
    )}`;
  }

  return '';
};

export const parse = (data, roundNumber = true) => {
  if (data !== null && typeof data === 'string') {
    data = data?.split(' ');

    if (roundNumber) {
      return data.length !== 1
        ? (parseFloat(data[0]) * multipliers[data[1]]).toFixed(2)
        : parseFloat(data[0]).toFixed(2);
    }

    return data.length !== 1
      ? parseFloat(data[0]) * multipliers[data[1]]
      : parseFloat(data[0]);
  }

  return 0;
};

export const priceFormat = (price, decimals = 2, roundNumber = true) => {
  if (price !== null) {
    let p = price;

    if (roundNumber && typeof p === 'number') {
      p = Math.floor(p * 100) / 100;
      return p.toFixed(decimals);
    }

    return intl.formatNumber(p, {
      maximumFractionDigits: decimals,
      minimumFractionDigits: 2,
    });
  }

  return '0.00';
};

export const getEncodedAddress = (address, keyring) => {
  if (address) {
    return keyring.encodeAddress(address, 69);
  }

  return '';
};

export const generateErrorMessage = (error, returnMessage = false) => {
  let err = '';

  if (error?.data?.message && error?.data?.message !== '') {
    err = error?.data?.message;
  } else if (error?.message && error?.message !== '') {
    err = error?.message;
  } else {
    err = 'Error: Action cannot be performed.';
  }

  if (err.includes('revert') && err.split('revert').length === 2) {
    err = err.split('revert')[1].trim();
  }

  if (returnMessage) {
    return err;
  }

  showErrorNotify(err, TOAST_ID);
};

export const getNetworkIcon = (network) => {
  switch (network) {
    case SORA:
      return 'images/sora.svg';
    case ASTAR:
      return 'images/astar.svg';
    case ETHEREUM:
      return 'images/eth.svg';
    default:
      return 'images/sora.svg';
  }
};
