export const scrollbarGradient = `linear-gradient(270deg, #FFE13B -106.78%, #CC6923 64.89%)`;
export const inputGradient = `linear-gradient(0deg, #EDD6B9 0%, #D4B492 99.95%)`;
export const white = '#FFFFFF';
export const black = '#000000';
export const grey = '#F2F2F2';
export const grey2 = '#FCFCFD';
export const glow = '#66efc1';
export const pink = '#FFC0AD';
export const purple = '#59173E';
export const green = '#29ffb2';
export const yellow = '#FFE13B';
export const yellow2 = '#9E7043';
export const yellow3 = '#BE966F';
export const brown = '#B54D2F';

export const colors = {
  scrollbarGradient,
  inputGradient,
  white,
  black,
  grey,
  grey2,
  glow,
  pink,
  purple,
  green,
  yellow,
  yellow2,
  yellow3,
  brown,
};
