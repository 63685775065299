import styled from 'styled-components/macro';

import { colors } from '../../styles/colors';
import { fontSizes, fontStyles } from '../../styles/fonts';

import { isSafari } from 'react-device-detect';

export const LeaderboardContent = styled.div`
  width: 685px;
  height: 628px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
`;

export const LeaderboardTitleContainer = styled.div`
  background-image: url(images/leaderboard_title.png);
  height: 67px;
  width: 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LeaderboardTitle = styled.span`
  ${fontStyles[fontSizes.twentyFour]}
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  margin-bottom: 5px;
  color: ${colors.grey2};
`;

export const LeaderboardItemContainer = styled.div`
  background-image: url(images/leaderboard_item_container.png);
  height: 93px;
  width: 641px;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  filter: ${({ userAddress }) =>
    userAddress
      ? `${isSafari ? `none` : `drop-shadow(0px 0px 5px ${colors.glow})`}`
      : `none`};
`;

export const LeaderboardItemIndexContainer = styled.div`
  background-image: url(images/leaderboard_item_index.png);
  height: 50px;
  width: 54px;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LeaderboardItemRightContainer = styled.div`
  background-image: url(images/leaderboard_item_right_container.png);
  height: 50px;
  width: 100px;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

export const ContainerRow = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
`;

export const LeaderboardItemIndexText = styled.span`
  ${fontStyles[fontSizes.twenty]}
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: ${colors.grey2};
`;

export const LeaderboardItemSpanContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 375px;
  overflow: hidden;
  padding-left: 15px;
`;

export const LeaderboardItemLogoContainer = styled.div`
  display: flex;
  width: 45px;
  justify-content: center;
  position: relative;
`;

export const LeaderboardItemLogosContainer = styled.div`
  display: flex;
  width: 45px;
  position: relative;
`;

export const LeaderboardItemAddress = styled.span`
  ${fontStyles[fontSizes.fourteen]}
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 15px;
  text-overflow: ellipsis;
  color: ${colors.grey2};
`;

export const LeaderboardItemNetwork = styled.img`
  width: 30px;
  height: auto;
  z-index: 10;
`;

export const LeaderboardItemNetworkRight = styled.img`
  width: 30px;
  height: auto;
  position: absolute;
  left: 15px;
`;

export const PaginationContainer = styled.div`
  height: 36px;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const PaginationButtonContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ButtonImage = styled.div`
  background-image: ${({ image }) => `url(${image})`};
  background-repeat: no-repeat;
  width: 36px;
  height: 36px;
  background-size: contain;
  background-position: center;
`;

export const PaginationButtonText = styled.span`
  ${fontStyles[fontSizes.sixteen]}
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  color: ${({ active }) => (active ? `${colors.white}` : `${colors.white}60`)};
`;

export const PaginationButtonPage = styled.button`
  background-image: ${({ active }) =>
    active ? `url(images/pagination_page.png)` : `none`};
  background-repeat: no-repeat;
  width: 36px;
  height: 36px;
  border: none;
  outline: none;
  background-size: contain;
  box-shadow: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2px;
  padding-right: 2px;
`;
