import GlobalStyle from './styles/globalStyle';

import 'simplebar/src/simplebar.css';

import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Router from './route';

const App = () => {
  return (
    <>
      <GlobalStyle />
      <Router />
      <ToastContainer
        limit={3}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
        theme='dark'
        draggable={false}
        transition={Zoom}
      />
    </>
  );
};

export default App;
