import * as Styled from './spinner.styled';

const Spinner = () => {
  return (
    <Styled.Spinner>
      <Styled.Bar1 />
      <Styled.Bar2 />
      <Styled.Bar3 />
      <Styled.Bar4 />
      <Styled.Bar5 />
      <Styled.Bar6 />
      <Styled.Bar7 />
      <Styled.Bar8 />
      <Styled.Bar9 />
      <Styled.Bar10 />
      <Styled.Bar11 />
      <Styled.Bar12 />
    </Styled.Spinner>
  );
};

export default Spinner;
