import styled from 'styled-components/macro';

import { fontSizes, fontStyles } from '../../styles/fonts';
import { colors } from '../../styles/colors';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
  overflow-x: auto;
  background: ${colors.black};
`;

export const LoadingContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Loading = styled.span`
  ${fontStyles[fontSizes.fourty]}
  font-style: normal;
  font-weight: 600;
  text-align: center;
  color: ${colors.grey2};
`;
