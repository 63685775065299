import styled from 'styled-components/macro';

import { colors } from '../../styles/colors';
import { device } from '../../styles/device';
import { fontSizes, fontStyles } from '../../styles/fonts';

import { isSafari } from 'react-device-detect';

export const Container = styled.div`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
`;

export const InnerContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  background-image: url(images/header_bg.png);
  height: 82px;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;

  @media ${device.laptop} {
    height: calc(100vw / 15.61);
  }

  @media ${device.tablet} {
    height: 65.6px;
  }
`;

export const Wrapper = styled.div`
  display: inherit;
`;

export const HeaderPanel = styled.div`
  width: 293px;
  background-image: url(images/header_panel.png);
  height: 205px;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const Button = styled.div`
  background-image: ${({ image }) => `url(${image})`};
  background-repeat: no-repeat;
  background-size: contain;
  height: 82px;
  width: 205px;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: ${isSafari
    ? `none`
    : `drop-shadow(0px 0px 32px rgba(0, 0, 0, 0.67))`};
  ${fontStyles[fontSizes.eighteen]}
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  color: ${colors.white};
  text-shadow: -1px 1px 1px rgba(57, 13, 13, 0.67);

  &:hover {
    filter: ${isSafari ? `none` : `drop-shadow(0px 0px 15px ${colors.glow})`};
    color: ${colors.green};
  }

  @media ${device.laptop} {
    ${fontStyles[fontSizes.sixteen]}
    height: calc(100vw / 15.61);
    width: calc(100vw / 15.61 * 2.5);
  }

  @media ${device.tablet} {
    height: 65.6px;
    width: 164px;
  }
`;

export const ButtonLink = styled.a.attrs({
  target: 'blank',
})`
  background-image: ${({ image }) => `url(${image})`};
  background-repeat: no-repeat;
  background-size: contain;
  text-decoration: none;
  cursor: pointer;
  height: 82px;
  width: 205px;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: ${isSafari
    ? `none`
    : `drop-shadow(0px 0px 32px rgba(0, 0, 0, 0.67))`};
  ${fontStyles[fontSizes.eighteen]}
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  color: ${colors.white};
  text-shadow: -1px 1px 1px rgba(57, 13, 13, 0.67);

  &:hover {
    filter: ${isSafari ? `none` : `drop-shadow(0px 0px 15px ${colors.glow})`};
    color: ${colors.green};
  }

  @media ${device.laptop} {
    ${fontStyles[fontSizes.sixteen]}
    height: calc(100vw / 15.61);
    width: calc(100vw / 15.61 * 2.5);
  }

  @media ${device.tablet} {
    height: 65.6px;
    width: 164px;
  }
`;

export const BuyButton = styled.div`
  background-image: url(images/buy_button.svg);
  background-repeat: no-repeat;
  width: 70px;
  height: 44px;
  background-size: contain;
  margin-bottom: 30px;
`;

export const WalletButton = styled.button`
  background-image: url(images/wallet_button.png);
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 25px;
  height: 70px;
  width: 171.72px;
  border: none;
  outline: none;
  box-shadow: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: ${isSafari
    ? `none`
    : `drop-shadow(0px 0px 32px rgba(0, 0, 0, 0.67))`};

  @media ${device.laptop} {
    margin-top: 20px;
  }

  @media ${device.tablet} {
    margin-top: 15px;
  }
`;

export const WalletButtonNetwork = styled.img`
  width: 30px;
  height: auto;
  margin-right: 5px;
`;

export const WalletButtonText = styled.span`
  ${fontStyles[fontSizes.twenty]}
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  color: ${colors.grey2};
  text-shadow: -1px 1px 1px rgba(115, 7, 26, 0.67);
  max-width: 120px;
`;

export const WalletButtonSelectedText = styled.span`
  ${fontStyles[fontSizes.sixteen]}
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  color: ${colors.grey2};
  text-shadow: -1px 1px 1px rgba(115, 7, 26, 0.67);
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const WalletMenuContainer = styled.div`
  width: 400px;
  background-image: url(images/wallet_container.png);
  background-repeat: no-repeat;
  background-size: contain;
  height: 406.42px;
  padding: 70px 50px;
  position: relative;
`;

export const WalletMenuDEOContainer = styled.div`
  width: 208px;
  height: 69px;
  background-image: url(images/balance_container.png);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: -15px;
  left: -25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const BalanceLabel = styled.span`
  ${fontStyles[fontSizes.sixteen]}
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  mix-blend-mode: normal;
  text-shadow: -1px 1px 1px rgba(57, 13, 13, 0.67);
  color: ${colors.grey2};
`;

export const WalletItemWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const WalletSeparator = styled.img`
  width: 100%;
  height: auto;
  margin: 5px 0;
`;

export const WalletItemNameContainer = styled.div`
  background-image: url(images/wallet_name_container.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 59px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: ${({ selected }) =>
    selected
      ? `${isSafari ? `none` : `drop-shadow(0px 0px 5px ${colors.glow})`}`
      : `none`};
`;

export const WalletNetworkLogo = styled.img`
  width: 50px;
  height: 50px;
  margin: 5px 0;
`;

export const WalletItemContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 10px;

  &:hover ${WalletItemNameContainer} {
    filter: ${isSafari ? `none` : `drop-shadow(0px 0px 5px ${colors.glow})`};
  }
`;

export const WalletItemTextContainer = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 20px 0 10px;
`;

export const WalletShortName = styled.span`
  ${fontStyles[fontSizes.eighteen]}
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  color: ${colors.white};
`;

export const DisconnectContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const DisconnectText = styled.p`
  ${fontStyles[fontSizes.fourteen]}
  font-style: normal;
  font-weight: 700;
  text-align: center;
  padding: 5px 15px;
  border-radius: 20px;
  border: 2px solid ${colors.brown};
  background: ${colors.purple};
  color: ${colors.pink};
  cursor: pointer;
`;

export const AddressContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px 15px;
  border-radius: 20px;
  background: ${colors.pink};
`;

export const AddressText = styled.span`
  ${fontStyles[fontSizes.fourteen]}
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  color: ${colors.purple};
`;

export const AddressCopy = styled.span`
  ${fontStyles[fontSizes.fourteen]}
  font-style: normal;
  font-weight: 700;
  cursor: pointer;
  color: ${colors.purple};
`;

export const ConnectWallet = styled.span`
  ${fontStyles[fontSizes.twentyTwo]}
  font-style: normal;
  font-weight: 700;
  color: ${colors.white};
`;

export const WalletName = styled.span`
  ${fontStyles[fontSizes.sixteen]}
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  color: ${colors.pink};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

export const WalletAddress = styled.span`
  ${fontStyles[fontSizes.twelve]}
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: ${colors.white};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

export const WalletMenuNoWallet = styled.span`
  ${fontStyles[fontSizes.sixteen]}
  color: ${colors.white};
  font-weight: normal;
`;

export const WalletMenuNoWalletLink = styled.a.attrs({
  target: 'blank',
})`
  color: ${colors.pink};
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
`;
