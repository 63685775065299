import { useState } from 'react';

import * as Styled from './chips.styled';

import {
  SpacerHorizontal,
  SpacerVertical,
} from '../../components/spacer/spacer';
import Input from '../../components/input/input';
import FullscreenContainer from '../../components/fullscreen_container/fullscreen_container';
import ButtonAsync from '../../components/button/button_async';
import CustomLoader from '../../components/loader/custom_loader';

import { priceFormat } from '../../utils/helpers';
import { showErrorNotify } from '../../utils/toast';

import {
  CHIPS_AMOUNT_ERROR,
  GREATER_THAN_ZERO,
  NOT_A_NUMBER,
  REQUIRED_FIELD,
  TOKEN_NAME,
  CHIPS_CLAIM_AMOUNT_ERROR,
  NOT_ENOUGH_ON_WALLET,
} from '../../constants';

import usePolkadot from '../../hooks/use_polkadot';
import useChips from '../../hooks/use_chips';

const ChipsForm = ({ mode, deoBalance, chips, buy, claim, walletBalance }) => {
  const [amount, setAmount] = useState('');

  const validateForm = () => {
    let isValid = true;

    if (amount === '') {
      isValid = false;
      showErrorNotify(REQUIRED_FIELD, true);
    } else {
      const amountNumber = parseFloat(amount);

      if (isNaN(amountNumber)) {
        isValid = false;
        showErrorNotify(NOT_A_NUMBER, true);
      } else if (amountNumber <= 0) {
        isValid = false;
        showErrorNotify(GREATER_THAN_ZERO, true);
      } else if (mode === 1 && amountNumber > walletBalance) {
        isValid = false;
        showErrorNotify(NOT_ENOUGH_ON_WALLET, true);
      } else if (mode === 0 && amountNumber > deoBalance) {
        isValid = false;
        showErrorNotify(CHIPS_AMOUNT_ERROR, true);
      } else if (mode === 1 && amountNumber > chips) {
        isValid = false;
        showErrorNotify(CHIPS_CLAIM_AMOUNT_ERROR, true);
      }
    }

    return isValid;
  };

  const handleClick = async () => {
    if (validateForm()) {
      if (mode === 0) {
        await buy(parseFloat(amount));
      } else {
        await claim(amount);
      }
    }
  };

  return (
    <>
      <Styled.ModalInputContainer>
        <Input
          placeholder={'1'}
          value={amount}
          handleChange={(e) => setAmount(e.target.value)}
        />
      </Styled.ModalInputContainer>
      <SpacerHorizontal size={15} />
      <Styled.InputTrailing>
        {mode === 0
          ? 'Enter the amount of game chips that you would like to purchase'
          : 'Enter the amount of game chips that you would like to claim back'}
      </Styled.InputTrailing>
      <SpacerHorizontal size={25} />
      <ButtonAsync
        title={mode === 0 ? 'Buy' : 'Claim'}
        image={'images/button_lg.png'}
        onClick={async () => await handleClick()}
      />
    </>
  );
};

const ChipsContent = () => {
  const [mode, setMode] = useState(0);

  const { deoBalance } = usePolkadot();

  const { buy, chips, loading, claim, walletBalance } = useChips();

  if (loading) {
    return <CustomLoader fullscreen={false} />;
  }

  return (
    <>
      <Styled.ChipsPanelContainer>
        <Styled.ChipsPanel
          image={'images/chips_panel_left.png'}
          onClick={() => setMode(0)}
        >
          <Styled.ChipsPanelLabel selected={mode === 0}>
            Buy Game Chips
          </Styled.ChipsPanelLabel>
        </Styled.ChipsPanel>
        <Styled.ChipsPanel
          image={'images/chips_panel_right.png'}
          onClick={() => setMode(1)}
        >
          <Styled.ChipsPanelLabel selected={mode === 1}>
            Claim back DEO
          </Styled.ChipsPanelLabel>
        </Styled.ChipsPanel>
      </Styled.ChipsPanelContainer>
      <SpacerHorizontal size={25} />
      <Styled.BalanceRow>
        <Styled.BalanceLabel>BALANCE:</Styled.BalanceLabel>
        <SpacerVertical size={15} />
        <Styled.BalanceContainer>
          <Styled.Balance>{`${priceFormat(
            deoBalance
          )} ${TOKEN_NAME}`}</Styled.Balance>
          <Styled.Balance>{`${priceFormat(chips)} Chips`}</Styled.Balance>
        </Styled.BalanceContainer>
      </Styled.BalanceRow>
      <SpacerHorizontal size={25} />
      <Styled.InputLabel>
        {mode === 0 ? 'Number of Chips TO BUY' : 'Number of DEO to claim'}
      </Styled.InputLabel>
      <SpacerHorizontal size={15} />
      <ChipsForm
        mode={mode}
        deoBalance={deoBalance}
        chips={chips}
        buy={buy}
        claim={claim}
        walletBalance={walletBalance}
      />
    </>
  );
};

const Chips = () => {
  return (
    <FullscreenContainer>
      <Styled.ChipsContainer>
        <ChipsContent />
      </Styled.ChipsContainer>
    </FullscreenContainer>
  );
};

export default Chips;
