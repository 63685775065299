import React from 'react';

import { HOME_PERMALINK } from '../../constants';

import { Navigate } from 'react-router-dom';

const RouteGuard = () => {
  return <Navigate to={HOME_PERMALINK} replace />;
};

export default RouteGuard;
