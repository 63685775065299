import styled from 'styled-components/macro';

export const Container = styled.div`
  position: absolute;
  z-index: 999;
  inset: 0;
  background: #00000080;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Loader = styled.div`
  background-image: url(images/loader.png);
  height: 274px;
  width: 276px;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
`;
