import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { PolkadotProvider } from './contexts/polkadot_context';

import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';

import history from './utils/history';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <IntlProvider locale='en'>
      <PolkadotProvider>
        <BrowserRouter history={history}>
          <App />
        </BrowserRouter>
      </PolkadotProvider>
    </IntlProvider>
  </React.StrictMode>
);
