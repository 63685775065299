import { useState } from 'react';

import * as Styled from './referral.styled';

import useReferralCode from '../../hooks/use_referral_code';

import { SpacerHorizontal } from '../../components/spacer/spacer';
import FullscreenContainer from '../../components/fullscreen_container/fullscreen_container';
import Input from '../../components/input/input';
import ButtonAsync from '../../components/button/button_async';
import CustomLoader from '../../components/loader/custom_loader';

import { showErrorNotify, showSuccessNotify } from '../../utils/toast';

import { REQUIRED_FIELD, TOAST_ID } from '../../constants';

import { CopyToClipboard } from 'react-copy-to-clipboard';

const Form = ({ sendReferralCode }) => {
  const [referralCode, setReferralCode] = useState('');

  const validateForm = () => {
    let isValid = true;

    if (referralCode === '') {
      isValid = false;
      showErrorNotify(REQUIRED_FIELD, true);
    }

    return isValid;
  };

  return (
    <>
      <Styled.ModalInputContainer>
        <Input
          placeholder={'Enter code'}
          type={'text'}
          fullWidth
          value={referralCode}
          handleChange={(e) => setReferralCode(e.target.value)}
        />
      </Styled.ModalInputContainer>
      <SpacerHorizontal size={25} />
      <ButtonAsync
        title={'Use code'}
        image={'images/button_lg.png'}
        onClick={async () => {
          if (validateForm()) {
            sendReferralCode(referralCode);
          }
        }}
      />
    </>
  );
};

const ReferralContent = () => {
  const { loading, referral, sendReferralCode } = useReferralCode();

  if (loading) {
    return <CustomLoader fullscreen={false} />;
  }

  return (
    <>
      <Styled.Panel>
        <Styled.PanelLabel>Your referral code:</Styled.PanelLabel>
        <Styled.PanelLabel bottomSpace>{referral?.code}</Styled.PanelLabel>
        <CopyToClipboard
          text={referral?.code}
          onCopy={() => showSuccessNotify('Referral code copied.', TOAST_ID)}
        >
          <ButtonAsync title={'Copy'} small image={'images/button_lg.png'} />
        </CopyToClipboard>
      </Styled.Panel>
      {referral?.id === 0 && (
        <>
          <SpacerHorizontal size={25} />
          <Styled.InputLabel>Enter the referral code</Styled.InputLabel>
          <SpacerHorizontal size={15} />
          <Form sendReferralCode={sendReferralCode} />
        </>
      )}
    </>
  );
};

const Referral = () => {
  return (
    <FullscreenContainer>
      <Styled.Container>
        <ReferralContent />
      </Styled.Container>
    </FullscreenContainer>
  );
};

export default Referral;
