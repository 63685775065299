import styled from 'styled-components/macro';

import { colors } from '../../styles/colors';
import { fontSizes, fontStyles } from '../../styles/fonts';
import { device } from '../../styles/device';

export const InputContainer = styled.div`
  display: flex;
  height: 38px;
  width: ${({ fullWidth }) => (fullWidth ? '217px' : `110px`)};
  border-radius: 20px;
  overflow: hidden;
  background: ${colors.inputGradient};
  background-blend-mode: multiply;
  mix-blend-mode: multiply;
`;

export const InputField = styled.input`
  height: 100%;
  width: 100%;
  font-size: ${({ fullWidth }) => (fullWidth ? '14px' : `24px`)};
  color: ${colors.black};
  font-weight: 400;
  border-radius: 20px;
  border: none;
  background-color: transparent;
  padding: ${({ fullWidth }) => (fullWidth ? '20px' : `10px`)};
  text-align: ${({ fullWidth }) => (fullWidth ? 'left' : `center`)};

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #ffffff90;
  }
`;

export const InputErrorText = styled.span`
  ${fontStyles[fontSizes.fourteen]}
  display: block;
  color: ${colors.white};
  margin-top: 8px;
  text-align: left;
  width: ${({ inputWidth }) => (inputWidth ? inputWidth : `100%`)};

  @media ${device.mobileXS} {
    ${fontStyles[fontSizes.twelve]}
  }
`;
