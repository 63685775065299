import { useState, useEffect } from 'react';

import { DEMETER_LITEPAPER, GAME_MEDIUM } from '../../constants';

import usePolkadot from '../../hooks/use_polkadot';

import { IoCloseOutline, IoMenuOutline } from 'react-icons/io5';

const FloatingButton = () => {
  const { loading } = usePolkadot();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 992) {
      setOpen(true);
    }
  }, []);

  if (!loading) {
    return (
      <div
        className={`floating-container ${
          open ? 'floating-container-open' : ''
        }`}
      >
        <div
          className={`floating-button blue ${
            open ? 'floating-button-open' : ''
          }`}
          onClick={() => setOpen(!open)}
        >
          {open ? (
            <IoCloseOutline size={30} color='white' />
          ) : (
            <IoMenuOutline size={30} color='white' />
          )}
        </div>
        <div className='element-container'>
          <a href={GAME_MEDIUM} target={'_blank'} rel='noreferrer'>
            <div
              className={`float-element ${open ? 'float-element-open' : ''}`}
            >
              <span className='float-element-label'>Game details</span>
            </div>
          </a>
          <a href={DEMETER_LITEPAPER} target={'_blank'} rel='noreferrer'>
            <div
              className={`float-element ${open ? 'float-element-open' : ''}`}
            >
              <span className='float-element-label'>Litepaper</span>
            </div>
          </a>
        </div>
      </div>
    );
  }

  return null;
};

export default FloatingButton;
