import * as Styled from './loader.styled';

import Spinner from '../spinner/spinner';
import usePolkadot from '../../hooks/use_polkadot';

const Loader = () => {
  const { loading } = usePolkadot();

  if (loading) {
    return (
      <Styled.Container>
        <Styled.Loader>
          <Spinner />
        </Styled.Loader>
      </Styled.Container>
    );
  }

  return null;
};

export default Loader;
