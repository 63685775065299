import { useEffect } from 'react';

import * as Styled from './fullscreen_container.styled';

import { useNavigate, useLocation } from 'react-router-dom';
import { HOME_PERMALINK } from 'constants';

const FullscreenContainer = ({ children }) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        handleBackNavigation();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBackNavigation = () => {
    if (state) {
      navigate(-1);
    } else {
      navigate(HOME_PERMALINK, { replace: true });
    }
  };

  return (
    <Styled.Container>
      <Styled.Wrapper>
        <Styled.Mask onClick={() => handleBackNavigation()} />
        {children}
      </Styled.Wrapper>
    </Styled.Container>
  );
};

export default FullscreenContainer;
