import styled from 'styled-components/macro';

import { colors } from '../../styles/colors';

export const ButtonContainer = styled.button`
  border: none;
  outline: none;
  box-shadow: none;
  background: transparent;
  cursor: pointer;
`;

export const ButtonAsync = styled.button`
  background-image: ${({ image }) => `url(${image})`};
  background-repeat: no-repeat;
  width: 217px;
  height: 62px;
  border: none;
  outline: none;
  background-size: contain;
  background-position: center;
  box-shadow: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonText = styled.span`
  font-size: ${({ small }) => (small ? '18px' : '24px')};
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  margin-bottom: 5px;
  text-align: center;
  color: ${colors.grey2};
`;
