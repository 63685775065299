import { Routes, Route } from 'react-router-dom';

import {
  CHIPS_PERMALINK,
  GAME_PERMALINK,
  HOME_PERMALINK,
  LEADERBOARD_PERMALINK,
  REFERRAL_PERMALINK,
} from '../constants';

import Home from '../pages/home/home';
import Leaderboard from '../pages/leaderboard/leaderboard';
import Chips from '../pages/chips/chips';
import RouteGuard from '../pages/route_guard/route_guard';
import Game from '../pages/game/game';
import Referral from '../pages/referral/referral';

const Router = () => {
  return (
    <Routes>
      <Route exact path={HOME_PERMALINK} element={<Home />}>
        <Route path={CHIPS_PERMALINK} element={<Chips />} />
        <Route path={REFERRAL_PERMALINK} element={<Referral />} />
        <Route path={LEADERBOARD_PERMALINK} element={<Leaderboard />} />
      </Route>
      <Route exact path={GAME_PERMALINK} element={<Game />} />
      <Route path='*' element={<RouteGuard />} />
    </Routes>
  );
};

export default Router;
