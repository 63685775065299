import styled from 'styled-components/macro';

import { colors } from '../../styles/colors';
import { fontSizes, fontStyles } from '../../styles/fonts';

export const ChipsContainer = styled.div`
  background: url(images/modal_container.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 392px;
  height: 527px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  z-index: 1;
  justify-content: center;
`;

export const ChipsPanelContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ChipsPanelLabel = styled.span`
  ${fontStyles[fontSizes.twenty]}
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: ${({ selected }) => (selected ? `${colors.glow}` : `${colors.white}`)};
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
`;

export const ChipsPanel = styled.div`
  background-image: ${({ image }) => `url(${image})`};
  height: 129px;
  width: 144px;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
  cursor: pointer;

  &:hover ${ChipsPanelLabel} {
    color: ${colors.glow};
  }
`;

export const BalanceRow = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
`;

export const BalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

export const BalanceLabel = styled.span`
  ${fontStyles[fontSizes.eighteen]}
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: ${colors.black};
`;

export const Balance = styled.span`
  ${fontStyles[fontSizes.eighteen]}
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  width: 100%;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  color: ${colors.black};
`;

export const InputLabel = styled.span`
  ${fontStyles[fontSizes.fifteen]}
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: ${colors.yellow2};
`;

export const ModalInputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const InputTrailing = styled.span`
  ${fontStyles[fontSizes.twelve]}
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  text-align: center;
  max-width: 250px;
  color: ${colors.yellow2};
`;
