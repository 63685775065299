import styled from 'styled-components/macro';

import { colors } from '../../styles/colors';
import { device } from '../../styles/device';
import { fontSizes, fontStyles } from '../../styles/fonts';

export const Container = styled.div`
  overflow: hidden;
  min-width: 1024px;
  position: relative;
`;

export const MainSection = styled.div`
  background-image: url(images/bg1.jpg);
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Background2 = styled.div`
  position: absolute;
  top: calc(-1 * (100vw / 19.53 / 2));
  background-image: url(images/bg2.png);
  width: 100%;
  height: calc(100vw / 19.53);
  background-size: cover;
  background-repeat: no-repeat, no-repeat;

  @media ${device.tablet} {
    height: 52.43px;
    top: -26.22px;
  }
`;

export const Logo = styled.img`
  width: 597px;
  height: 428px;
`;

export const PlayButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(images/play_button.png);
  background-repeat: no-repeat;
  width: 254.6px;
  height: 89px;
  background-size: contain;
  font-style: normal;
  font-weight: 700;
  ${fontStyles[fontSizes.fourty]}
  line-height: 35px;
  text-align: center;
  color: ${colors.grey2};
  text-shadow: -2px 1px 1px rgba(57, 13, 13, 0.67);

  &:hover {
    filter: drop-shadow(0px 0px 15px ${colors.glow});
    color: ${colors.green};
  }
`;

export const Section = styled.div`
  position: relative;
  background-image: url(images/bg3.jpg);
  height: calc(100vw / 2.12);
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 50px;

  @media ${device.laptopL} {
    padding-bottom: 35px;
  }

  @media ${device.tablet} {
    height: 483px;
  }
`;

export const Panel = styled.div`
  background-image: ${({ image }) => `url(${image})`};
  height: calc(100vw / 2.12 * 0.6);
  width: ${({ aspect }) => `calc((100vw / 2.12 * 0.6) / ${aspect})`};
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.tablet} {
    height: 290px;
    width: ${({ aspect }) => `calc(290px / ${aspect})`};
  }
`;

export const BuyButton = styled.div`
  background-image: ${({ image }) => `url(${image})`};
  background-repeat: no-repeat;
  width: 70px;
  height: 44px;
  background-size: contain;
  margin-bottom: ${({ bottom }) => (bottom ? `${bottom}px` : `0`)};
  margin-left: ${({ left }) => (left ? `${left}px` : `0`)};

  @media ${device.laptopXL} {
    margin-bottom: ${({ bottom }) => (bottom ? `${bottom - 15}px` : `0`)};
    margin-left: ${({ left }) => (left ? `${left}px` : `0`)};
  }

  @media ${device.laptopL} {
    width: 60px;
    height: 37.5px;
    margin-bottom: ${({ bottom }) => (bottom ? `${bottom - 25}px` : `0`)};
    margin-left: ${({ left }) => (left ? `${left - 10}px` : `0`)};
  }

  @media ${device.laptop} {
    width: 50px;
    height: 31.25px;
    margin-bottom: ${({ bottom }) => (bottom ? `${bottom - 40}px` : `0`)};
  }
`;
