import styled from 'styled-components/macro';

import { device } from '../../styles/device';

export const Container = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  min-width: 1024px;
  background: #00000080;

  @media ${device.tablet} {
    position: absolute;
  }
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Mask = styled.div`
  position: absolute;
  inset: 0;
`;
