import { useCallback, useState, useEffect } from 'react';

import { web3FromSource } from '@polkadot/extension-dapp';

import { getEncodedAddress } from '../utils/helpers';
import { showErrorNotify, showSuccessNotify } from '../utils/toast';

import usePolkadot from './use_polkadot';

import {
  ASTAR,
  ETHEREUM,
  REFERRAL_CODE_API,
  SORA,
  TOAST_ID,
  USER_INFO_API,
} from '../constants';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const useReferralCode = () => {
  const [loading, setLoading] = useState(true);
  const [referral, setReferral] = useState({
    code: '',
    id: '',
  });

  const navigate = useNavigate();

  const { selectedAccount, keyring, network, networkProvider } = usePolkadot();

  const stopLoading = useCallback(async () => {
    if (loading) {
      setLoading(false);
    }
  }, [loading]);

  const getUserInfo = useCallback(async () => {
    const address =
      network?.id === SORA
        ? getEncodedAddress(selectedAccount?.address, keyring)
        : selectedAccount?.address;

    await axios
      .get(`${USER_INFO_API}/${address}`)
      .then((response) => {
        if (response.data) {
          setReferral({
            code: response.data?.data?.referral_code,
            id: response.data?.data?.referral_user_id,
          });
        }
        stopLoading();
      })
      .catch(() => {
        stopLoading();
      });
  }, [selectedAccount, keyring, stopLoading, network]);

  const init = useCallback(async () => {
    await getUserInfo();
  }, [getUserInfo]);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount]);

  const sendReferralCode = useCallback(
    async (code) => {
      const message = 'Use referral code';
      let address = '';
      let signature;

      if (network?.id === SORA) {
        address = getEncodedAddress(selectedAccount?.address, keyring);
        const injector = await web3FromSource(selectedAccount?.meta?.source);
        const signRaw = injector?.signer?.signRaw;

        if (!!signRaw) {
          const signerResult = await signRaw({
            address: address,
            data: message,
            type: 'bytes',
          });
          signature = signerResult?.signature;
        }
      }

      if (network?.id === ASTAR || network?.id === ETHEREUM) {
        address = selectedAccount?.address;
        try {
          const signer = await networkProvider?.getSigner();
          signature = await signer?.signMessage(message);
        } catch (error) {
          showErrorNotify(error, TOAST_ID);
        }
      }

      const config = {
        method: 'post',
        url: `${REFERRAL_CODE_API}/${address}`,
        data: {
          referralCode: code,
          signature: signature,
          network: network?.id,
        },
      };

      await axios(config)
        .then((response) => {
          if (response.data) {
            showSuccessNotify('Referral code successfully used!', TOAST_ID);
            navigate(-1);
          } else {
            showErrorNotify("Can't use referral code!", true, TOAST_ID);
          }
        })
        .catch(() => {
          showErrorNotify("Can't use referral code!", true, TOAST_ID);
        });
    },
    [selectedAccount, keyring, navigate, network, networkProvider]
  );

  return { loading, referral, sendReferralCode };
};

export default useReferralCode;
