import { useState } from 'react';

import * as Styled from './header.styled';

import Tippy from '@tippyjs/react';
import SimpleBarReact from 'simplebar-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { SpacerHorizontal, SpacerVertical } from '../spacer/spacer';
import Button from '../button/button';

import usePolkadot from '../../hooks/use_polkadot';

import {
  firstName,
  formatWalletAddress,
  getAvatarTitle,
  getNetworkIcon,
  priceFormat,
} from '../../utils/helpers';
import { showSuccessNotify } from '../../utils/toast';

import {
  ASTAR,
  ETHEREUM,
  POLKADOT_EXTENSION,
  SORA,
  TOAST_ID,
  TOKEN_NAME,
} from '../../constants';

const Header = ({
  buyDEOToken,
  openChipsModal,
  openReferralModal,
  openLeaderboardModal,
}) => {
  const [visible, setVisible] = useState(false);

  const { selectedAccount, network } = usePolkadot();

  const closeMenu = () => {
    setVisible(false);
  };

  const openMenu = () => {
    setVisible(true);
  };

  const icon = getNetworkIcon(network?.id);

  return (
    <Styled.Container>
      <Styled.InnerContainer>
        <Styled.Wrapper>
          <Styled.HeaderPanel>
            <Button callback={() => buyDEOToken()}>
              <Styled.BuyButton />
            </Button>
          </Styled.HeaderPanel>
          <Button callback={openChipsModal}>
            <Styled.Button image={'images/chips_button.png'}>
              Buy Game Chips
            </Styled.Button>
          </Button>
          <Button callback={openReferralModal}>
            <Styled.Button image={'images/leaderboard_button.png'}>
              Referral Code
            </Styled.Button>
          </Button>
          <Button walletRequired={false} callback={openLeaderboardModal}>
            <Styled.Button image={'images/chips_button.png'}>
              Leaderboard
            </Styled.Button>
          </Button>
        </Styled.Wrapper>
        <Tippy
          visible={visible}
          content={
            <WalletMenu
              closeMenu={closeMenu}
              selectedAccount={selectedAccount}
            />
          }
          animation='fade'
          onClickOutside={() => closeMenu()}
          placement={'bottom-end'}
          appendTo='parent'
          interactive='true'
          duration={0}
          maxWidth='none'
          offset={[0, 0]}
        >
          <Styled.WalletButton onClick={() => openMenu()}>
            {network?.id && selectedAccount ? (
              <>
                <Styled.WalletButtonNetwork src={icon} />
                <Styled.WalletButtonSelectedText>
                  {network?.id === SORA
                    ? firstName(selectedAccount?.meta?.name)
                    : selectedAccount?.address}
                </Styled.WalletButtonSelectedText>
              </>
            ) : (
              <Styled.WalletButtonText>Connect wallet</Styled.WalletButtonText>
            )}
          </Styled.WalletButton>
        </Tippy>
      </Styled.InnerContainer>
    </Styled.Container>
  );
};

const PolkadotMenu = ({ selectedAccount, closeMenu }) => {
  const { accounts, saveSelectedAccount, switchNetwork } = usePolkadot();

  if (accounts?.length > 0) {
    return (
      <>
        {accounts?.map((account) => {
          const selected = selectedAccount?.address === account?.address;

          return (
            <Styled.WalletItemWrapper key={account?.address}>
              <Styled.WalletItemContainer
                onClick={() => {
                  if (!selected) {
                    saveSelectedAccount(account, SORA);
                  }

                  closeMenu();
                }}
              >
                <Styled.WalletItemNameContainer selected={selected}>
                  <Styled.WalletShortName>
                    {getAvatarTitle(account?.meta?.name)}
                  </Styled.WalletShortName>
                </Styled.WalletItemNameContainer>
                <Styled.WalletItemTextContainer>
                  <Styled.WalletName>{account?.meta?.name}</Styled.WalletName>
                  <Styled.WalletAddress>
                    {formatWalletAddress(account?.address)}
                  </Styled.WalletAddress>
                </Styled.WalletItemTextContainer>
              </Styled.WalletItemContainer>
              <Styled.WalletSeparator src={'images/wallet_separator.png'} />
            </Styled.WalletItemWrapper>
          );
        })}
        <SpacerHorizontal size={20} />
        <Styled.DisconnectContainer>
          <Styled.DisconnectText onClick={() => switchNetwork()}>
            Disconnect
          </Styled.DisconnectText>
        </Styled.DisconnectContainer>
      </>
    );
  }

  return (
    <Styled.WalletMenuNoWallet>
      Please add wallet using Polkadot JS extension. If you don't have Polkadot
      JS extension installed, please click on this
      <Styled.WalletMenuNoWalletLink href={POLKADOT_EXTENSION}>
        {' '}
        Link
      </Styled.WalletMenuNoWalletLink>
      .
      <SpacerHorizontal size={20} />
      <Styled.DisconnectContainer>
        <Styled.DisconnectText onClick={() => switchNetwork()}>
          Disconnect
        </Styled.DisconnectText>
      </Styled.DisconnectContainer>
    </Styled.WalletMenuNoWallet>
  );
};

const AstarMenu = ({ selectedAccount }) => {
  const { switchNetwork, connectToChain, network } = usePolkadot();

  if (selectedAccount) {
    return (
      <>
        <SpacerHorizontal size={20} />
        <Styled.ConnectWallet>Your address</Styled.ConnectWallet>
        <SpacerHorizontal size={20} />
        <Styled.AddressContainer>
          <Styled.AddressText>{selectedAccount?.address}</Styled.AddressText>
          <SpacerVertical size={5} />
          <CopyToClipboard
            text={selectedAccount?.address}
            onCopy={() => showSuccessNotify('Address copied.', TOAST_ID)}
          >
            <Styled.AddressCopy>Copy</Styled.AddressCopy>
          </CopyToClipboard>
        </Styled.AddressContainer>

        <SpacerHorizontal size={20} />
        <Styled.DisconnectContainer>
          <Styled.DisconnectText onClick={() => switchNetwork()}>
            Disconnect
          </Styled.DisconnectText>
        </Styled.DisconnectContainer>
      </>
    );
  }

  return (
    <>
      <Styled.WalletMenuNoWallet>
        Please connect your wallet using Metamask.
      </Styled.WalletMenuNoWallet>
      <SpacerHorizontal size={20} />
      <Styled.DisconnectContainer>
        <Styled.DisconnectText onClick={() => connectToChain(network)}>
          Connect wallet
        </Styled.DisconnectText>
      </Styled.DisconnectContainer>
    </>
  );
};

const NoChainMenu = () => {
  const { networkOptions, switchNetwork } = usePolkadot();

  return (
    <>
      <Styled.ConnectWallet>Connect Wallet</Styled.ConnectWallet>
      <SpacerHorizontal size={20} />
      {networkOptions?.map((network) => (
        <Styled.WalletItemWrapper key={network?.id}>
          <Styled.WalletItemContainer onClick={() => switchNetwork(network)}>
            <Styled.WalletNetworkLogo src={network?.logo} />
            <Styled.WalletItemTextContainer>
              <Styled.WalletName>{network?.id}</Styled.WalletName>
            </Styled.WalletItemTextContainer>
          </Styled.WalletItemContainer>
          <Styled.WalletSeparator src={'images/wallet_separator.png'} />
        </Styled.WalletItemWrapper>
      ))}
    </>
  );
};

const WalletMenu = ({ closeMenu, selectedAccount }) => {
  const { deoBalance, network } = usePolkadot();

  const renderWalletMenu = () => {
    switch (network?.id) {
      case SORA:
        return (
          <PolkadotMenu
            selectedAccount={selectedAccount}
            closeMenu={closeMenu}
          />
        );
      case ASTAR:
        return <AstarMenu selectedAccount={selectedAccount} />;
      case ETHEREUM:
        return <AstarMenu selectedAccount={selectedAccount} />;
      default:
        return <NoChainMenu />;
    }
  };

  return (
    <Styled.WalletMenuContainer>
      <SimpleBarReact
        style={{ height: '306.42px', paddingRight: '20px' }}
        classNames={{
          scrollbar: 'simplebar-scrollbar',
        }}
      >
        {renderWalletMenu()}
      </SimpleBarReact>
      {selectedAccount && (
        <Styled.WalletMenuDEOContainer>
          <Styled.BalanceLabel>BALANCE:</Styled.BalanceLabel>
          <SpacerHorizontal size={5} />
          <Styled.BalanceLabel>{`${priceFormat(
            deoBalance
          )} ${TOKEN_NAME}`}</Styled.BalanceLabel>
        </Styled.WalletMenuDEOContainer>
      )}
    </Styled.WalletMenuContainer>
  );
};

export default Header;
