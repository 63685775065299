import * as Styled from './button.styled';

import { CONNECT_WALLET_ERROR, TOAST_ID } from '../../constants';

import usePolkadot from '../../hooks/use_polkadot';

import { showErrorNotify } from '../../utils/toast';

const Button = ({
  callback,
  children,
  walletRequired = true,
  disabled = false,
}) => {
  const { selectedAccount } = usePolkadot();

  const handleButtonClick = () => {
    if (walletRequired && !selectedAccount) {
      showErrorNotify(CONNECT_WALLET_ERROR, true, TOAST_ID);
    } else {
      callback();
    }
  };

  return (
    <Styled.ButtonContainer
      disabled={disabled}
      onClick={() => handleButtonClick()}
    >
      {children}
    </Styled.ButtonContainer>
  );
};

export default Button;
