import { useCallback } from 'react';

import { getEncodedAddress } from '../utils/helpers';
import { showErrorNotify } from '../utils/toast';

import usePolkadot from './use_polkadot';

import {
  GAME_PERMALINK,
  USER_INFO_API,
  CHIP_AMOUNT_API,
  SORA,
  ASTAR,
  ETHEREUM,
} from '../constants';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const useGame = () => {
  const { selectedAccount, network, keyring } = usePolkadot();

  const navigate = useNavigate();

  const checkIfEnoughChips = useCallback(async () => {
    let address = '';

    if (network?.id === SORA) {
      address = getEncodedAddress(selectedAccount?.address, keyring);
    }

    if (network?.id === ASTAR || network?.id === ETHEREUM) {
      address = selectedAccount?.address;
    }

    const response = await Promise.all([
      await axios.get(`${USER_INFO_API}/${address}`),
      await axios.get(CHIP_AMOUNT_API),
    ]);

    if (
      response[0]?.data?.data?.chips &&
      Number(response[0]?.data?.data?.chips) >=
        Number(response[1]?.data?.amount)
    ) {
      navigate(GAME_PERMALINK);
    } else {
      showErrorNotify(
        `You need minimum ${response[1]?.data?.amount} chips to play the game`,
        true
      );
    }
  }, [navigate, network, keyring, selectedAccount]);

  return { checkIfEnoughChips };
};

export default useGame;
