import { useCallback, useState, useEffect } from 'react';

import { web3FromSource } from '@polkadot/extension-dapp';
import { ethers } from 'ethers';

import usePolkadot from './use_polkadot';

import {
  CLAIM_CHIPS_API,
  DEMETER_ADDRESS,
  USER_INFO_API,
  CHIPS_WALLET_ADDRESS,
  CHIPS_WALLET_ADDRESS_ASTAR,
  SORA,
  ASTAR,
  ETHEREUM,
} from '../constants';

import { FPNumber } from '../utils/FPNumber.ts';
import { showLoadingNotify, updateNotify } from '../utils/toast';
import { getEncodedAddress, parse } from '../utils/helpers';

import { toast } from 'react-toastify';
import axios from 'axios';

const useChips = () => {
  const [loading, setLoading] = useState(true);
  const [chips, setChips] = useState(0);
  const [walletBalance, setWalletBalance] = useState(0);

  const {
    api,
    selectedAccount,
    keyring,
    getDeoBalance,
    network,
    tokenContract,
    networkProvider,
  } = usePolkadot();

  const stopLoading = useCallback(async () => {
    if (loading) {
      setLoading(false);
    }
  }, [loading]);

  const getUserInfo = useCallback(async () => {
    let balance = 0;
    let address = '';

    if (network?.id === SORA) {
      address = getEncodedAddress(selectedAccount?.address, keyring);
      balance = await api.rpc?.assets?.freeBalance(
        CHIPS_WALLET_ADDRESS,
        DEMETER_ADDRESS
      );
      setWalletBalance(parse(balance?.toHuman()?.balance, false));
    }

    if (network?.id === ASTAR || network?.id === ETHEREUM) {
      address = selectedAccount?.address;

      const tokenBalance = await tokenContract?.balanceOf(
        CHIPS_WALLET_ADDRESS_ASTAR
      );
      setWalletBalance(ethers.utils.formatUnits(tokenBalance));
    }

    await axios
      .get(`${USER_INFO_API}/${address}`)
      .then((response) => {
        if (response.data) {
          setChips(Number(response.data?.data?.chips));
        }
        stopLoading();
      })
      .catch(() => {
        stopLoading();
      });
  }, [api, selectedAccount, keyring, stopLoading, network, tokenContract]);

  const buy = useCallback(
    async (amount) => {
      const toastID = showLoadingNotify();

      if (network?.id === SORA) {
        const injector = await web3FromSource(selectedAccount?.meta?.source);

        const transferExtrinsic = api?.tx?.assets?.transfer(
          DEMETER_ADDRESS,
          CHIPS_WALLET_ADDRESS,
          FPNumber.fromNatural(amount).bnToString()
        );

        await transferExtrinsic
          ?.signAndSend(
            selectedAccount?.address,
            { signer: injector?.signer },
            ({ status, events }) => {
              if (status?.isInBlock) {
                events
                  .filter(({ event }) =>
                    api?.events?.system?.ExtrinsicFailed?.is(event)
                  )
                  .forEach(
                    ({
                      event: {
                        data: [error, info],
                      },
                    }) => {
                      if (error.isModule) {
                        const decoded = api?.registry?.findMetaError(
                          error.asModule
                        );
                        updateNotify(
                          toastID,
                          `Transaction failed : ${decoded.docs[0]}`,
                          toast.TYPE.ERROR
                        );
                      } else {
                        updateNotify(
                          toastID,
                          `Transaction failed : ${error}`,
                          toast.TYPE.ERROR
                        );
                      }
                    }
                  );
                updateNotify(
                  toastID,
                  'Chips successfully bought',
                  toast.TYPE.SUCCESS
                );

                setTimeout(() => {
                  getUserInfo();
                  getDeoBalance();
                }, 2000);
              }
            }
          )
          .catch((error) => {
            updateNotify(
              toastID,
              `Transaction failed : ${error}`,
              toast.TYPE.ERROR
            );
          });
      }

      if (network?.id === ASTAR || network?.id === ETHEREUM) {
        try {
          const tx = await tokenContract?.transfer(
            CHIPS_WALLET_ADDRESS_ASTAR,
            ethers.utils.parseUnits(amount?.toString())
          );

          const transactionReceipt = await tx.wait();

          if (transactionReceipt?.status === 1) {
            updateNotify(
              toastID,
              'Chips successfully bought',
              toast.TYPE.SUCCESS
            );
            setTimeout(() => {
              getUserInfo();
              getDeoBalance();
            }, 2000);
          }
        } catch (error) {
          updateNotify(toastID, 'Transaction failed', toast.TYPE.ERROR);
        }
      }
    },
    [api, selectedAccount, getUserInfo, getDeoBalance, network, tokenContract]
  );

  const claim = useCallback(
    async (amount) => {
      const toastID = showLoadingNotify();

      const message = 'Claim back DEO';
      let address = '';
      let signature;

      const timeout = network?.id === SORA ? 2000 : 8000;

      if (network?.id === SORA) {
        address = getEncodedAddress(selectedAccount?.address, keyring);
        const injector = await web3FromSource(selectedAccount?.meta?.source);
        const signRaw = injector?.signer?.signRaw;

        if (!!signRaw) {
          const signerResult = await signRaw({
            address: address,
            data: message,
            type: 'bytes',
          });
          signature = signerResult?.signature;
        }
      }

      if (network?.id === ASTAR || network?.id === ETHEREUM) {
        address = selectedAccount?.address;

        try {
          const signer = await networkProvider?.getSigner();
          signature = await signer?.signMessage(message);
        } catch (error) {
          updateNotify(toastID, 'DEO claiming failed.', toast.TYPE.ERROR);
        }
      }

      const config = {
        method: 'post',
        url: `${CLAIM_CHIPS_API}/${address}`,
        data: {
          deo: amount,
          signature: signature,
          network: network?.id,
        },
      };

      await axios(config)
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            const isClaimed = response.data;

            if (isClaimed) {
              updateNotify(
                toastID,
                'DEO successfully claimed',
                toast.TYPE.SUCCESS
              );
              setTimeout(() => {
                getUserInfo();
                getDeoBalance();
              }, timeout);
            } else {
              updateNotify(toastID, 'DEO claiming failed.', toast.TYPE.ERROR);
            }
          } else {
            updateNotify(toastID, 'DEO claiming failed.', toast.TYPE.ERROR);
          }
        })
        .catch(() => {
          updateNotify(toastID, 'DEO claiming failed.', toast.TYPE.ERROR);
        });
    },
    [
      selectedAccount,
      keyring,
      getUserInfo,
      getDeoBalance,
      network,
      networkProvider,
    ]
  );

  const init = useCallback(async () => {
    await getUserInfo();
  }, [getUserInfo]);

  useEffect(() => {
    init();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount]);

  return { buy, loading, chips, claim, walletBalance };
};

export default useChips;
