export const APP_NAME = 'DEO Arena';
export const TOKEN_NAME = 'DEO';

export const SORA_BUILDER_PROGRAM =
  'https://medium.com/sora-xor/sora-builders-programme-979bea8831ed';
export const DEMETER_LITEPAPER =
  'https://ceres-token.s3.eu-central-1.amazonaws.com/docs/Ceres%2BToken%2B-%2BDemeter%2BLitepaper%2B09.12.2021.pdf';
export const GAME_MEDIUM =
  'https://tokenceres.medium.com/deo-arena-game-details-update-and-nft-boost-premint-989fdcd4e0e8';

export const CHIPS_WALLET_ADDRESS =
  'cnV2yJNWAqoxCKWiGCgSDGC9j25TwfgsPVPea89458ZRP7yAr';
export const CHIPS_WALLET_ADDRESS_ASTAR =
  '0x68D824C2853C9B4496cD96f7F993b8Cd42a2c5b7';
export const DEMETER_ADDRESS =
  '0x00f2f4fda40a4bf1fc3769d156fa695532eec31e265d75068524462c0b80f674';
export const DEMETER_ADDRESS_ASTAR =
  '0xb51541df05de07be38dcfc4a80c05389a54502bb';
export const DEMETER_ADDRESS_ETHEREUM =
  '0x5E7F20e72C21f6D0Bf0A2814FD4164176401cf8e';

// TOAST ID //
export const TOAST_ID = 'ToastID';

// SORA API //
export const SORA_API_TEST = 'wss://ws.stage.sora2.soramitsu.co.jp';
export const SORA_API = 'wss://mof2.sora.org';
export const POLKADOT_ACCOUNT = 'POLKADOT_ACCOUNT';
export const POLKADOT_EXTENSION = 'https://polkadot.js.org/extension/';
export const METAMASK_EXTENSION = 'https://metamask.io/download/';

// BACKEND API //
export const USER_INFO_API = 'https://api.deotoken.com/api/gamers';
export const CHIP_AMOUNT_API = 'https://api.deotoken.com/api/chips/amount';
export const CLAIM_CHIPS_API =
  'https://api.deotoken.com/api/gamers/claim-back-deo';
export const REFERRAL_CODE_API =
  'https://api.deotoken.com/api/gamers/use-referral-code';
export const RANKING_API = 'https://api.deotoken.com/api/gamers/ranking';

// EXTERNAL LINKS //
export const POLKASWAP = 'https://polkaswap.io/';
export const ZENLINK = 'http://dex.zenlink.pro/';
export const POLKASWAP_SWAP = 'https://polkaswap.io/#/swap';
export const CERES_WEBSITE = 'https://cerestoken.io/';
export const CERES_TELEGRAM = 'https://t.me/cerestoken';
export const CERES_TWITTER = 'https://twitter.com/TokenCeres';
export const CERES_LINKEDIN = 'https://www.linkedin.com/company/77012167';
export const CERES_MEDIUM = 'https://tokenceres.medium.com/';

export const CONNECT_WALLET_ERROR = 'Please, connect your wallet first.';
export const NO_MORE_NFTS = 'There are no NFTs left';
export const REQUIRED_FIELD = 'This field is required!';
export const NOT_A_NUMBER = 'You must enter a number value';
export const GREATER_THAN_ZERO = 'You must enter a number greater than 0';
export const NOT_ENOUGH_ON_WALLET = 'Insufficient DEO on the wallet';
export const ONE_OR_TWO = 'You must enter 1 or 2';
export const NFT_COUNT_ERROR = 'One NFT is a maximum per wallet';
export const AMOUNT_ERROR =
  '(NFT price * entered amount) must be less or equal than DEO balance';
export const CHIPS_AMOUNT_ERROR =
  'Entered amount must be less or equal than DEO balance';
export const CHIPS_CLAIM_AMOUNT_ERROR =
  'Entered amount must be less or equal than Chips balance';
export const NOT_ENOUGH_NFTS = 'Not enough NFTs left';

// ROUTE PERMALINKS //
export const HOME_PERMALINK = '/';
export const LEADERBOARD_PERMALINK = '/leaderboard';
export const CHIPS_PERMALINK = '/game-chips';
export const REFERRAL_PERMALINK = '/referral-code';
export const GAME_PERMALINK = '/game';

// NETWORK //
export const SORA = 'SORA';
export const ASTAR = 'ASTAR';
export const ETHEREUM = 'ETHEREUM';

// ASTAR //
export const ASTAR_CHAIN_ID = '0x250';

// ETHEREUM //
export const ETHEREUM_CHAIN_ID = '0x1';
