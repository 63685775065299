import styled from 'styled-components/macro';

import { colors } from '../../styles/colors';
import { fontSizes, fontStyles } from '../../styles/fonts';

export const Container = styled.div`
  background: url(images/modal_container.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 392px;
  height: 527px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  z-index: 1;
`;

export const Panel = styled.div`
  background-image: url(images/nft_panel.png);
  height: 156px;
  width: 275px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
`;

export const PanelLabel = styled.span`
  font-size: ${({ bottomSpace }) => (bottomSpace ? '12px' : '16px')};
  font-style: normal;
  font-weight: 600;
  text-align: center;
  margin-bottom: ${({ bottomSpace }) => (bottomSpace ? '20px' : '0')};
  color: ${colors.white};
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
`;

export const BalanceRow = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
`;

export const BalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

export const BalanceLabel = styled.span`
  ${fontStyles[fontSizes.eighteen]}
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: ${colors.black};
`;

export const Balance = styled.span`
  ${fontStyles[fontSizes.eighteen]}
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  width: 100%;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  color: ${colors.black};
`;

export const InputLabel = styled.span`
  ${fontStyles[fontSizes.fifteen]}
  font-style: normal;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  color: ${colors.yellow2};
`;

export const ModalInputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalInputPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const InputPriceDeo = styled.span`
  ${fontStyles[fontSizes.fourteen]}
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: ${colors.yellow2};
`;

export const InputPriceDollars = styled.span`
  ${fontStyles[fontSizes.fourteen]}
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: ${colors.yellow3};
`;

export const InputTrailing = styled.span`
  ${fontStyles[fontSizes.twelve]}
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  text-align: center;
  max-width: 250px;
  color: ${colors.yellow2};
`;
