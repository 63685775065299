import * as Styled from './footer.styled';

import { SpacerVertical, SpacerHorizontal } from '../spacer/spacer';

import {
  SORA_BUILDER_PROGRAM,
  CERES_WEBSITE,
  CERES_TWITTER,
  CERES_TELEGRAM,
  CERES_MEDIUM,
  CERES_LINKEDIN,
} from '../../constants';

import {
  FaGlobe,
  FaMediumM,
  FaTwitter,
  FaTelegramPlane,
  FaLinkedinIn,
} from 'react-icons/fa';

import { useMediaQuery } from 'react-responsive';

import { device } from '../../styles/device';

import Button from '../button/button';

const Footer = ({
  buyDEOToken,
  openChipsModal,
  openReferralModal,
  openLeaderboardModal,
}) => {
  const isLaptop = useMediaQuery({ query: device.laptop });

  return (
    <Styled.Container>
      <Styled.TopSection>
        <Styled.Wrapper>
          <Styled.LogoArena src={'images/logo.png'} />
        </Styled.Wrapper>
        <Styled.LinkWrapper>
          <Button callback={buyDEOToken}>
            <Styled.LinkButton>Buy DEO Token</Styled.LinkButton>
          </Button>
          <SpacerHorizontal size={isLaptop ? 15 : 25} />
          <Button callback={openChipsModal}>
            <Styled.LinkButton>Buy Game Chips</Styled.LinkButton>
          </Button>
          <SpacerHorizontal size={isLaptop ? 15 : 25} />
          <Button callback={openReferralModal}>
            <Styled.LinkButton>Referral Code</Styled.LinkButton>
          </Button>
          <SpacerHorizontal size={isLaptop ? 15 : 25} />
          <Button walletRequired={false} callback={openLeaderboardModal}>
            <Styled.LinkButton>Leaderboard</Styled.LinkButton>
          </Button>
        </Styled.LinkWrapper>
        <Styled.IconsWrapper>
          <Styled.LogoLink href={CERES_WEBSITE}>
            <FaGlobe size={30} className='fa_icons' />
          </Styled.LogoLink>
          <SpacerVertical size={20} />
          <Styled.LogoLink href={CERES_TWITTER}>
            <FaTwitter size={30} className='fa_icons' />
          </Styled.LogoLink>
          <SpacerVertical size={20} />
          <Styled.LogoLink href={CERES_TELEGRAM}>
            <FaTelegramPlane size={30} className='fa_icons' />
          </Styled.LogoLink>
          <SpacerVertical size={20} />
          <Styled.LogoLink href={CERES_MEDIUM}>
            <FaMediumM size={30} className='fa_icons' />
          </Styled.LogoLink>
          <SpacerVertical size={20} />
          <Styled.LogoLink href={CERES_LINKEDIN}>
            <FaLinkedinIn size={30} className='fa_icons' />
          </Styled.LogoLink>
        </Styled.IconsWrapper>
      </Styled.TopSection>
      <Styled.BottomSection>
        <Styled.BottomContainer>
          <Styled.InnerContainer>
            <Styled.WrapperColumn>
              <Styled.Label>Supported by</Styled.Label>
              <SpacerHorizontal size={5} />
              <Styled.LogoLink href={SORA_BUILDER_PROGRAM}>
                <Styled.LogoSora src={'images/sora-builders-programme.svg'} />
              </Styled.LogoLink>
            </Styled.WrapperColumn>
            <Styled.Copyright>Copyright © 2022 CBS LLC</Styled.Copyright>
            <Styled.WrapperLogos>
              <Styled.Logo src={'images/ceres.svg'} />
              <SpacerVertical size={20} />
              <Styled.Logo src={'images/demeter.svg'} />
              <SpacerVertical size={20} />
              <Styled.Logo src={'images/hermes.svg'} />
              <SpacerVertical size={20} />
              <Styled.Logo src={'images/apollo.svg'} />
            </Styled.WrapperLogos>
          </Styled.InnerContainer>
        </Styled.BottomContainer>
      </Styled.BottomSection>
    </Styled.Container>
  );
};

export default Footer;
